import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Column, HeaderColumn, PageSection, Row, Table, TableBody, TableHead, } from '@gordon/web-ui';
import { useState } from 'react';
import { Exceptions } from './Exceptions';
import { EditRateCardModal } from '../EditRateCardModal';
import { amount } from '../../utils/helpers';
export const Overview = ({ rateCard, setData, showEditButton = true }) => {
    const [showEditRateCardModal, setShowEditRateCardModal] = useState(false);
    if (!rateCard) {
        return null;
    }
    const [rateCardDefaultSection, ...exceptions] = rateCard.sections;
    const defaultArticles = rateCardDefaultSection.thresholds[0].rates.map((rate) => rate.article);
    const currency = rateCard.currency;
    const formatItemsPerOrderText = (quantity) => {
        if (!quantity)
            return '0 items';
        if (quantity === 1)
            return '1 item';
        else
            return `${quantity} items`;
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageSection, { size: "h2", headingText: "Default prices", supportingText: "These prices will be applied to all depots besides any exceptions.", actionsRight: showEditButton && (_jsx(Button, { variant: "primary", text: "Edit rate card", onClick: () => setShowEditRateCardModal(true) })), children: _jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { children: [_jsx(HeaderColumn, { width: '30cqw', children: "Article name" }), rateCardDefaultSection.thresholds.map((threshold) => (_jsxs(HeaderColumn, { width: '50cqw', right: true, children: ["Threshold: ", threshold.threshold] }, threshold.threshold)))] }), _jsx(TableBody, { children: defaultArticles.map((article) => (_jsxs(Row, { children: [_jsx(Column, { text: article.name, supportText: article.calculate === 'item' ? '↳ Items per order' : undefined }), rateCardDefaultSection.thresholds.map((threshold) => {
                                        var _a, _b, _c;
                                        return (_jsx(Column, { right: true, text: amount((_a = threshold.rates.find((rate) => rate.article._id === article._id)) === null || _a === void 0 ? void 0 : _a.price, currency), supportText: article.calculate === 'item' ? (_jsx("div", { style: { textAlign: 'right' }, children: formatItemsPerOrderText((_b = threshold.rates.find((rate) => rate.article._id === article._id)) === null || _b === void 0 ? void 0 : _b.includedQuantity) })) : undefined }, (_c = threshold.rates.find((rate) => rate.article._id === article._id)) === null || _c === void 0 ? void 0 : _c._id));
                                    })] }, article._id))) })] }) }), _jsx(Exceptions, { exceptions: exceptions, currency: currency, formatItemsPerOrderText: formatItemsPerOrderText }), showEditRateCardModal && (_jsx(EditRateCardModal, { onClose: () => setShowEditRateCardModal(false), rateCard: rateCard, onSubmit: (updatedRateCard) => {
                    if (setData) {
                        setData(() => updatedRateCard);
                    }
                    setShowEditRateCardModal(false);
                } }))] }));
};
