import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Select, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useFetchDepotOptions } from '../../api';
import { AddArticlesTable } from './AddArticleTable';
export const CreateExceptionModal = ({ onClose, onAdd, availableArticles, alreadyUsedDepotIds, }) => {
    const [depots, setDepots] = useState([]);
    const [articles, setArticles] = useState([]);
    const depotOptions = useFetchDepotOptions();
    const depotOptionsFiltered = depotOptions.filter((option) => !alreadyUsedDepotIds.includes(option.value));
    return (_jsxs(Modal, { size: "medium", onClose: onClose, title: "Create exception", supportingText: "Select the depot/s and articles you would like to add to the new exception section from the areas below.", footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: "Add", onClick: () => onAdd(depots.map((id) => {
                        var _a, _b;
                        return ({
                            _id: id,
                            name: (_b = (_a = depotOptions.find((d) => d.value === id)) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : '',
                        });
                    }), articles), disabled: depots.length === 0 || articles.length === 0 })] }), children: [_jsx(ModalSection, { title: "Depots", children: _jsx(ModalRow, { children: _jsx(Select, { withSearch: true, options: depotOptionsFiltered, placeholder: "Select depots", multiselect: true, value: depots, onSelect: (values) => setDepots(values) }) }) }), _jsx(ModalSection, { title: "Articles", children: _jsx(ModalRow, { children: _jsx(AddArticlesTable, { articles: articles, availableArticles: availableArticles, setArticles: setArticles }) }) })] }));
};
