var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '../utils/helperFunctions';
import styles from './Table.module.scss';
import { TableContext } from './shared';
export function Table(_a) {
    var { fullBorder, sortedColumn, sortableColumns = [], sortDirection = 'asc', sort = () => { }, expandable = false, condensed = false, cellBorders = false, children } = _a, rest = __rest(_a, ["fullBorder", "sortedColumn", "sortableColumns", "sortDirection", "sort", "expandable", "condensed", "cellBorders", "children"]);
    return (_jsx(TableContext.Provider, { value: { sortDirection, sortedColumn, expandable, sortableColumns, sort, condensed }, children: _jsx("table", { style: Object.assign({}, rest), className: classNames(styles.base, fullBorder && styles.border, cellBorders && styles.cellBorders), children: children }) }));
}
