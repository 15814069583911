import { useCallback, useMemo } from 'react';
import { useQueryParamState } from './useQueryParamState';
function formatValue(value) {
    return typeof value === 'string' ? value.toLowerCase() : value;
}
export function useSort(list) {
    const [{ sortField, sortOrder }, setState] = useQueryParamState({
        sortField: '',
        sortOrder: 'asc',
    });
    const sortedList = useMemo(() => {
        if (!list || !sortField) {
            return list;
        }
        return [...list].sort((a, b) => {
            if (a[sortField] === undefined && b[sortField] === undefined) {
                return 0;
            }
            else if (a[sortField] == undefined ||
                formatValue(a[sortField]) < formatValue(b[sortField])) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            else if (b[sortField] == undefined ||
                formatValue(a[sortField]) > formatValue(b[sortField])) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            else {
                return 0;
            }
        });
    }, [list, sortField, sortOrder]);
    const changeSorting = useCallback((newSortField) => {
        if (newSortField === sortField) {
            setState('sortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
        }
        else {
            setState('sortField', newSortField);
            setState('sortOrder', 'asc');
        }
    }, [sortField, sortOrder]);
    return { sortedList, sortField, sortOrder, changeSorting };
}
