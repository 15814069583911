import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styles from './Link.module.scss';
import { Link as RouterLink } from 'react-router-dom';
import onlyText, { classNames } from '../utils/helperFunctions';
export function Link({ children, href, target, size = 'standard', ellipsis = false, variant = 'secondary', phone, email, underline, active = false, disabled = false, }) {
    const title = useMemo(() => (ellipsis ? onlyText(children) : undefined), [ellipsis, children]);
    const shouldHaveTo = !email && !phone;
    return shouldHaveTo ? (_jsx(RouterLink, { title: title, className: classNames(styles.base, styles[`variant-${variant}`], styles[`size--${size}`], active && styles[`variant-${variant}--active`], disabled && styles[`variant-${variant}--disabled`], ellipsis && styles['ellipsis'], underline && styles['underline']), to: href, target: target, children: children })) : (_jsx(RouterLink, { title: title, onClick: (e) => {
            e.preventDefault();
            email
                ? (window.location.href = `mailto:${href}`)
                : phone
                    ? (window.location.href = `tel:${href}`)
                    : undefined;
        }, className: classNames(styles.base, styles[`variant-${variant}`], styles[`size--${size}`], ellipsis && styles['ellipsis'], underline && styles['underline']), to: '#', target: target, children: children }));
}
