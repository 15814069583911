import { useMemo } from 'react';
function getValueByPath(obj, path = '') {
    const keys = path.split('.');
    return keys.reduce((acc, key) => acc && acc[key] !== undefined ? acc[key] : undefined, obj);
}
export function useSearch(list, searchKeys, searchValue) {
    return useMemo(() => {
        if (!list || !searchValue)
            return list;
        return list.filter((item) => searchKeys.some((key) => {
            const value = getValueByPath(item, key);
            return String(value).toLowerCase().includes(String(searchValue).toLowerCase());
        }));
    }, [list, searchValue]);
}
