import { useEffect, useMemo } from 'react';
import { useAuthFetch } from './hooks/useFetch';
import { useQueryParamState } from './hooks/useQueryParamState';
import { capitalizeFirstLetter, prettifyCamelCaseText } from './utils/helpers';
export const useFetchInvoice = (id) => {
    return useAuthFetch(`/invoice-specs/${id}`, 'GET');
};
export const useAddInvoiceDetail = (id) => {
    return useAuthFetch(`/invoice-specs/${id}/add-invoice-detail`, 'POST');
};
export const useDeleteInvoiceDetail = (id) => {
    return useAuthFetch(`/invoice-specs/${id}/remove-invoice-detail`, 'POST');
};
export const useDeleteRateCard = (id) => {
    return useAuthFetch(`/rate-cards/${id}`, 'DELETE');
};
export const useAddRateCard = () => {
    return useAuthFetch('/rate-cards', 'POST');
};
export const useUpdateRateCard = (id) => {
    return useAuthFetch(`/rate-cards/${id}`, 'PATCH');
};
export const useUpdateInvoiceStatus = () => {
    return useAuthFetch('/invoice-specs/update-status', 'POST');
};
export const useRecalculateInvoices = () => {
    return useAuthFetch('/invoice-specs/recalculate', 'POST');
};
export const useFetchAccounts = (status) => {
    return useAuthFetch(`/invoice-accounts?active=${status === 'inactive' ? 'false' : 'true'}`, 'GET');
};
export const useFetchFeeTypeOptions = () => {
    const { data: feeTypes } = useAuthFetch(`/fee-types`, 'GET');
    return useMemo(() => feeTypes ? feeTypes.map((type) => ({ text: capitalizeFirstLetter(type), value: type })) : [], [feeTypes]);
};
export const useFetchDepotOptions = (accountId, triggerOnMount = true) => {
    const { data: depots, runRequest } = useAuthFetch(accountId ? `/depots?accountId=${accountId}` : `/depots`, 'GET');
    useEffect(() => {
        if (accountId && !triggerOnMount) {
            runRequest();
        }
    }, [accountId]);
    return useMemo(() => depots
        ? depots
            .filter(({ _id, name }) => !!_id && !!name)
            .map(({ _id, name }) => ({ text: name, value: _id }))
        : [], [depots]);
};
export const useFetchClientOptions = () => {
    const { data: clients } = useAuthFetch(`/invoice-clients`, 'GET');
    return useMemo(() => (clients ? clients.map(({ _id, name }) => ({ text: name, value: _id })) : []), [clients]);
};
export const useFetchAccountOptions = () => {
    const { data: accounts } = useAuthFetch(`/invoice-accounts?active=true`, 'GET');
    return useMemo(() => (accounts ? accounts.map(({ _id, name }) => ({ text: name, value: _id })) : []), [accounts]);
};
export const useFetchCurrencyOptions = () => {
    const { data: currencies } = useAuthFetch(`/currencies`, 'GET');
    return useMemo(() => currencies
        ? currencies.map((type) => ({ text: capitalizeFirstLetter(type), value: type }))
        : [], [currencies]);
};
export const useFetchInvoiceAccount = (id) => {
    return useAuthFetch(`/invoice-accounts/${id}`, 'GET');
};
export const useUpdateInvoiceAccount = () => {
    return useAuthFetch(`/invoice-accounts/update`, 'POST');
};
export const useUpdateInvoiceClient = () => {
    return useAuthFetch(`/invoice-clients/update`, 'POST');
};
export const useFetchClientsByInvoiceAccount = (id) => {
    return useAuthFetch(`/invoice-clients/invoice-account/${id}`, 'GET');
};
export const useFetchRateCardsByInvoiceAccount = (id, triggerOnMount = true) => {
    return useAuthFetch(`/rate-cards?invoiceAccount=${id}`, 'GET', { triggerOnMount });
};
export const useFetchRateCard = (id) => {
    return useAuthFetch(`/rate-cards/${id}`, 'GET');
};
export const useFetchChangeLogs = (id) => {
    return useAuthFetch(`/rate-cards/${id}/change-log`, 'GET');
};
export const useFetchChangeLogDetails = (id, versionId) => {
    return useAuthFetch(`/rate-cards/${id}?versionId=${versionId}`, 'GET');
};
export const useFetchClient = (id) => {
    return useAuthFetch(`/invoice-clients/${id}`, 'GET');
};
export const useFetchPriceModels = () => {
    return useAuthFetch(`/price-models`, 'GET');
};
export const useFetchPriceModel = (id) => {
    return useAuthFetch(`/price-models/${id}`, 'GET');
};
export const useUpdatePriceModel = () => {
    return useAuthFetch(`/price-models`, 'POST');
};
export const useAddPriceModel = () => {
    return useAuthFetch(`/price-models`, 'POST');
};
export const invoiceStatus = {
    NEW: 'New',
    NOT_CALCULATED: 'Not calculated',
    CALCULATING: 'Calculating',
    UNABLE_TO_CALCULATE: 'Unable to calculate',
    READY_TO_INVOICE: 'Ready to invoice',
    READY_TO_EXPORT: 'Ready to export',
    MANUALLY_HANDLED: 'Manually handled',
    NO_CHARGE: 'No charge',
    UNABLE_TO_EXPORT: 'Unable to export',
    EXPORTED: 'Invoice exported',
    SENT: 'Sent',
};
const fetchInvoicesParamState = {
    page: 1,
    pageSize: 20,
    invoiceStatuses: [],
    accountId: '',
    clientIds: [],
    searchString: '',
    startDate: '',
    endDate: '',
    sortOrder: -1,
    sortField: 'startDate',
    periodicity: '',
    currency: '',
};
export const useFetchInvoices = (idParam) => {
    const [state, setState, hasChanged, clearAll] = useQueryParamState(fetchInvoicesParamState);
    const queryParams = useMemo(() => {
        const params = new URLSearchParams();
        Object.entries(state).forEach(([key, value]) => (Array.isArray(value) ? value.length > 0 : value) && params.set(key, value));
        if (idParam.accountId)
            params.set('accountId', idParam.accountId);
        if (idParam.clientId)
            params.set('clientIds', idParam.clientId);
        return params.toString();
    }, [state]);
    const { data, isLoading, runRequest } = useAuthFetch(`/invoice-specs/find?${queryParams}`, 'GET');
    return Object.assign(Object.assign({ data: data === null || data === void 0 ? void 0 : data.results, isLoading }, state), { setPage: (page) => setState('page', page), setPageSize: (pageSize) => {
            setState('page', 1);
            setState('pageSize', pageSize);
        }, setParamState: (...args) => {
            setState('page', 1);
            setState(args[0], args[1]);
        }, totalInvoices: data === null || data === void 0 ? void 0 : data.total, numberOfPages: data ? Math.ceil((data === null || data === void 0 ? void 0 : data.total) / state.pageSize) : undefined, sortOrder: state.sortOrder === -1 ? 'asc' : 'desc', changeSorting: (sortField) => {
            if (sortField === state.sortField) {
                setState('sortOrder', state.sortOrder === -1 ? 1 : -1);
            }
            else {
                setState('sortField', sortField);
                setState('sortOrder', -1);
            }
        }, hasFiltersChanged: hasChanged, clearAllFilters: clearAll, refresh: runRequest });
};
export const useFetchArticles = () => {
    return useAuthFetch(`/articles`, 'GET');
};
export const useFetchCalculationOptions = () => {
    const { data } = useAuthFetch(`/articles/calculate-options`, 'GET');
    return useMemo(() => data
        ? data.map((type) => ({
            text: prettifyCamelCaseText(type),
            value: type,
        }))
        : [], [data]);
};
export const useAddArticle = () => {
    return useAuthFetch(`/articles`, 'POST');
};
export const useUpdateArticle = (id) => {
    return useAuthFetch(`/articles/${id}`, 'PATCH');
};
