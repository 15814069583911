var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@gordon/web-ui';
export const FilterDropdown = (_a) => {
    var { width = 136, options, placeholder, label, withSearch = false } = _a, props = __rest(_a, ["width", "options", "placeholder", "label", "withSearch"]);
    return (_jsx("div", { style: { minWidth: width }, "data-testid": `filter-dropdown-${placeholder}`, children: props.multiselect ? (_jsx(Select, { withSearch: withSearch && !!options, disabled: !options || options.length === 1, options: options, multiselect: true, withSelectAll: true, preview: 'count', placeholder: placeholder, label: label, value: props.value, onSelect: (values, clickedValue) => clickedValue === '___VIEW_ALL___'
                ? props.onUpdate(!options || options.length === props.value.length
                    ? []
                    : options.map(({ value }) => value))
                : props.onUpdate(values) })) : (_jsx(Select, { withSearch: withSearch && !!options, disabled: !options || options.length === 1, options: options, placeholder: placeholder, label: label, value: props.value, onSelect: props.onUpdate })) }));
};
