import { jsx as _jsx } from "react/jsx-runtime";
import './WeekdayPicker.scss';
import { Paragraph } from '../Typography';
export const WeekdayPicker = ({ pickedDays = [] }) => {
    const WEEKDAYS = [
        { text: 'M', value: 1 },
        { text: 'T', value: 2 },
        { text: 'W', value: 3 },
        { text: 'T', value: 4 },
        { text: 'F', value: 5 },
        { text: 'S', value: 6 },
        { text: 'S', value: 7 },
    ];
    return (_jsx("div", { className: "weekday-picker", children: WEEKDAYS.map((day) => {
            return (_jsx(Paragraph, { disabled: !pickedDays.includes(day.value), strong: true, inheritColor: pickedDays.includes(day.value), children: day.text }, day.value));
        }) }));
};
