import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, cloneElement, isValidElement, useContext, } from 'react';
import { Heading } from '../Typography';
import styles from './TableHead.module.scss';
import { TableContext } from './shared';
import { HeaderColumn } from './HeaderColumn';
import { classNames } from '../utils/helperFunctions';
export function TableHead({ children, actions, title, sticky = true, stickyTop = 0, }) {
    const { expandable, sortedColumn, sortableColumns, sort, sortDirection, condensed } = useContext(TableContext);
    return (_jsxs("thead", { className: classNames(styles.base, condensed && styles.condensed, sticky && styles.sticky), style: sticky ? { top: stickyTop } : undefined, children: [!title && !!actions && (_jsx("tr", { children: _jsx("th", { colSpan: 100, children: _jsx("div", { className: styles.actions, children: actions }) }) })), !!title ? (_jsx("tr", { children: _jsx("th", { colSpan: 100, children: _jsxs("div", { className: styles.wrapper, children: [title && _jsx(Heading, { size: "h3", children: title }), actions && _jsx("div", { children: actions })] }) }) })) : null, _jsxs("tr", { children: [Children.map(children, (child, index) => sortableColumns.includes(String(index)) && isValidElement(child)
                        ? cloneElement(child, {
                            isSelected: String(index) === sortedColumn,
                            isSortable: true,
                            onClick: (e) => {
                                if ((e === null || e === void 0 ? void 0 : e.target).tagName === 'SPAN') {
                                    return;
                                }
                                sort(String(index));
                            },
                            sortOrder: String(index) === sortedColumn ? sortDirection : 'asc',
                        })
                        : child), expandable && _jsx(HeaderColumn, { width: 48 })] })] }));
}
