import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { TableListContext } from './shared';
import { classNames } from '../utils/helperFunctions';
import { Checkbox } from '../Checkbox';
import { Link } from '../Link';
import { Paragraph } from '../Typography';
import { Tooltip } from '../Tooltip';
import { IconInfoCircle } from '../Icon';
import styles from './Column.module.scss';
function StatusDot({ disabled }) {
    return (_jsx("div", { className: classNames(styles['status-dot'], disabled && styles['status-dot--disabled']) }));
}
export function Column({ text, center = false, right = false, strong = false, status, statusText, span, tooltip, supportText, checked, disabled, onChange, href, children, linkTarget = '_self', }) {
    const inList = useContext(TableListContext);
    const Tag = inList ? 'div' : 'td';
    return (_jsx(Tag, { colSpan: span, className: classNames(styles.base, inList && styles['in-list']), children: children || (_jsx("div", { className: classNames(center && styles.center, right && styles.right), children: _jsxs(_Fragment, { children: [onChange && _jsx(Checkbox, { disabled: disabled, checked: checked, onChange: onChange }), text && (_jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.text, children: [href && !disabled ? (_jsx(Link, { href: href, target: linkTarget, ellipsis: true, variant: "primary", children: text })) : (_jsx(Paragraph, { tabular: true, strong: strong, disabled: disabled, ellipsis: true, children: text })), status === 'error' &&
                                        (statusText ? (_jsx(Tooltip, { title: statusText, position: "top", children: _jsx(StatusDot, { disabled: disabled }) })) : (_jsx(StatusDot, {}))), tooltip && (_jsx(Tooltip, { title: tooltip, position: "top", children: _jsx(IconInfoCircle, { color: disabled ? 'disabled' : 'strong' }) }))] }), supportText && (_jsx(Paragraph, { tabular: true, disabled: disabled, ellipsis: true, size: "small", children: supportText }))] }))] }) })) }));
}
