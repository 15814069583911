import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Select, IconCalendarDate, Input, Modal, ModalRow, ModalSection, } from '@gordon/web-ui';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAddInvoiceDetail, useFetchDepotOptions } from '../api';
import dayjs from 'dayjs';
import { formatDate, validateDateFormat } from '../utils/helpers';
export function CreateCostModal({ invoiceStart, invoiceEnd, costDetail, articleOptions, invoiceId, onSubmit, onClose, }) {
    var _a, _b, _c, _d, _e;
    const { register, watch, setValue, resetField, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: costDetail
            ? {
                _id: costDetail._id,
                deliveryDate: dayjs(costDetail.deliveryDate).format('YYYY-MM-DD'),
                depotId: costDetail.depotId,
                price: costDetail.price,
                quantity: costDetail.quantity,
                articleId: costDetail.article,
                description: costDetail.description,
            }
            : { deliveryDate: dayjs(new Date()).format('YYYY-MM-DD'), description: '' },
    });
    const [dateError, setDateError] = useState(false);
    const [dateWarning, setDateWarning] = useState(false);
    const depots = useFetchDepotOptions();
    const { isLoading, data, runRequest, error } = useAddInvoiceDetail(invoiceId);
    useEffect(() => {
        register('depotId', { required: 'Depot is required' });
        register('articleId', { required: 'Article is required' });
    }, []);
    const deliveryDate = watch('deliveryDate');
    const depotId = watch('depotId');
    const articleId = watch('articleId');
    const quantity = watch('quantity');
    const price = watch('price');
    const description = watch('description');
    useEffect(() => {
        if (data) {
            onSubmit();
        }
    }, [data]);
    const hasBeenModified = deliveryDate !== (costDetail === null || costDetail === void 0 ? void 0 : costDetail.deliveryDate) ||
        depotId !== (costDetail === null || costDetail === void 0 ? void 0 : costDetail.depotId) ||
        articleId !== (costDetail === null || costDetail === void 0 ? void 0 : costDetail.article) ||
        quantity !== (costDetail === null || costDetail === void 0 ? void 0 : costDetail.quantity) ||
        price !== (costDetail === null || costDetail === void 0 ? void 0 : costDetail.price) ||
        description !== (costDetail === null || costDetail === void 0 ? void 0 : costDetail.description);
    const linkedToDisabledArticle = articleId && !articleOptions.find((option) => option.value === articleId);
    const todaysDate = dayjs(new Date()).format('YYYY-MM-DD');
    const handleDateBlur = (event) => {
        const inputDate = event.target.value;
        const startDate = new Date(formatDate(invoiceStart));
        const endDate = new Date(formatDate(invoiceEnd));
        const isValidDate = validateDateFormat(inputDate);
        const inputDateFormatted = new Date(formatDate(inputDate));
        if (!(inputDateFormatted >= startDate && inputDateFormatted <= endDate)) {
            setDateWarning(true);
        }
        else {
            setDateWarning(false);
        }
        if (inputDate && isValidDate) {
            const formattedDate = dayjs(inputDate).format('YYYY-MM-DD');
            setDateError(false);
            setValue('deliveryDate', formattedDate);
        }
        else if (inputDate && !isValidDate) {
            setDateError(true);
            setValue('deliveryDate', '');
        }
        else {
            setDateError(false);
            setValue('deliveryDate', todaysDate);
        }
    };
    return (_jsx(Modal, { title: (costDetail === null || costDetail === void 0 ? void 0 : costDetail._id) ? 'Edit cost' : 'Create cost', onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", disabled: !deliveryDate ||
                        !depotId ||
                        !articleId ||
                        linkedToDisabledArticle ||
                        !quantity ||
                        !price ||
                        !hasBeenModified, text: "Save", isLoading: isLoading, onClick: handleSubmit((data) => {
                        runRequest(Object.assign(Object.assign({}, data), { deliveryDate: dayjs(data.deliveryDate).toISOString() }));
                    }) })] }), children: _jsxs(ModalSection, { children: [_jsx(ModalRow, { columns: 2, children: _jsx(Input, Object.assign({ errorMessage: dateError ? 'Invalid date' : ((_a = errors.deliveryDate) === null || _a === void 0 ? void 0 : _a.message) || '', error: dateError, icon: IconCalendarDate, label: "Date", placeholder: todaysDate }, register('deliveryDate', {
                        required: 'Date is required',
                    }), { onBlur: (event) => handleDateBlur(event) })) }), dateWarning && _jsx(Alert, { variant: "warning", heading: "Date is not with invoice date range" }), _jsx(Select, { withSearch: true, label: "Article name", placeholder: "Select", options: articleOptions, value: articleId, onSelect: (articleId) => articleId ? setValue('articleId', articleId) : resetField('articleId'), errorMessage: (_b = errors.articleId) === null || _b === void 0 ? void 0 : _b.message }), _jsx(Input, Object.assign({ label: "Description", placeholder: "Add an optional description" }, register('description'))), _jsx(Select, { label: "Depot", withSearch: true, placeholder: "Select", options: depots, value: depotId, onSelect: (depotId) => (depotId ? setValue('depotId', depotId) : resetField('depotId')), errorMessage: (_c = errors.depotId) === null || _c === void 0 ? void 0 : _c.message }), _jsxs(ModalRow, { children: [_jsx(Input, Object.assign({ type: "number", label: "Quantity", placeholder: "Add quantity" }, register('quantity', {
                            valueAsNumber: true,
                            required: 'Quantity is required',
                            min: { value: 0, message: 'Price must be greater than 0' },
                        }), { errorMessage: (_d = errors.quantity) === null || _d === void 0 ? void 0 : _d.message })), _jsx(Input, Object.assign({ type: "number", label: "Price", placeholder: "Add price" }, register('price', {
                            valueAsNumber: true,
                            required: 'Price is required',
                            validate: (value) => value !== 0,
                        }), { errorMessage: (_e = errors.price) === null || _e === void 0 ? void 0 : _e.message }))] }), error && _jsx(Alert, { variant: "error", heading: "Something went wrong" })] }) }));
}
