import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Column, HeaderColumn, LoadingSpinner, PageSection, Row, Table, TableBody, TableHead, } from '@gordon/web-ui';
import { useFetchChangeLogs } from '../../api';
import { formatDate, formatTime } from '../../utils/helpers';
import { ChangeLogModal } from './ChangeLogModal';
export const ChangeLogs = ({ rateCard }) => {
    if (!rateCard) {
        return null;
    }
    const [modal, setModal] = React.useState(null);
    const closeModal = () => setModal(null);
    const { data: changeLogs, isLoading } = useFetchChangeLogs(rateCard.sourceId);
    return (_jsx(_Fragment, { children: _jsxs(PageSection, { children: [isLoading && !changeLogs ? (_jsx(LoadingSpinner, {})) : (_jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { title: changeLogs && changeLogs.length > 0 ? `${changeLogs.length} change logs` : undefined, children: [_jsx(HeaderColumn, { width: '40cqw', children: "Date" }), _jsx(HeaderColumn, { width: '40cqw', children: "Time" }), _jsx(HeaderColumn, { width: '40cqw', children: "Editor" }), _jsx(HeaderColumn, { width: '5cqw' })] }), _jsx(TableBody, { children: changeLogs &&
                                changeLogs.map((changeLog) => (_jsxs(Row, { children: [_jsx(Column, { text: `${formatDate(changeLog.updated_at)}` }), _jsx(Column, { text: `${formatTime(changeLog.updated_at)}` }), _jsx(Column, { text: changeLog.createdBy.fullName }), _jsx(Column, { right: true, children: _jsx(Button, { onClick: () => {
                                                    setModal({
                                                        type: 'change_log_details',
                                                        versionId: changeLog._id,
                                                    });
                                                }, isLoading: isLoading, size: "small", text: "View", variant: "secondary" }) })] }, changeLog._id))) })] })), (modal === null || modal === void 0 ? void 0 : modal.type) === 'change_log_details' && (_jsx(ChangeLogModal, { onClose: () => {
                        closeModal();
                    }, sourceId: rateCard.sourceId, versionId: modal === null || modal === void 0 ? void 0 : modal.versionId }))] }) }));
};
