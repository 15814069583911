import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@gordon/web-ui';
export const PageSizeSelector = ({ pageSize, setPageSize, }) => {
    return (_jsx(Select, { variant: "tertiary", label: "Page size", options: [
            { text: '20', value: '20' },
            { text: '40', value: '40' },
            { text: '80', value: '80' },
            { text: '160', value: '160' },
        ], value: String(pageSize), onSelect: (newPageSize) => newPageSize && setPageSize(Number(newPageSize)) }));
};
