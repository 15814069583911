import { useMemo } from 'react';
function getValueByPath(obj, path) {
    const keys = path.split('.');
    return keys.reduce((acc, key) => acc && acc[key] !== undefined ? acc[key] : undefined, obj);
}
export function useFilter(list, filters) {
    return useMemo(() => {
        if (!list)
            return list;
        return list.filter((item) => {
            return Object.entries(filters).every((filter) => {
                const [key, values] = filter;
                const value = getValueByPath(item, key);
                if (!values) {
                    return true;
                }
                if (values.length === 0) {
                    return true;
                }
                if (Array.isArray(value)) {
                    return value.some((v) => values === String(v) || values.includes(String(v)));
                }
                return values === String(value) || values.includes(String(value));
            });
        });
    }, [list, ...Object.values(filters)]);
}
