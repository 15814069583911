import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { TableListContext } from './shared';
import { IconArrowDown, IconArrowUp, IconInfoCircle } from '../Icon';
import { classNames } from '../utils/helperFunctions';
import { Checkbox } from '../Checkbox';
import { Paragraph } from '../Typography';
import { Tooltip } from '../Tooltip';
import styles from './HeaderColumn.module.scss';
export function HeaderColumn({ isSortable, isSelected, sortOrder = '', onClick, children, span, tooltip, tooltipPosition, checked, isIntermediate = false, onChange, center = false, right = false, width, subHeaders, edgeSpacedSubHeaders, }) {
    const inList = useContext(TableListContext);
    const Tag = inList ? 'div' : 'th';
    const sortIcon = ['asc', 'ascending'].includes(sortOrder) ? (_jsx(IconArrowUp, { color: isSelected ? 'brand-primary' : 'default' })) : (_jsx(IconArrowDown, { color: isSelected ? 'brand-primary' : 'default' }));
    return (_jsxs(Tag, { className: classNames(styles.base, isSortable && styles['base--sort'], isSortable && isSelected && styles['base--sorted'], inList && styles['in-list']), colSpan: span, style: {
            width,
        }, onClick: onClick, children: [_jsxs("div", { children: [onChange && (_jsx(Checkbox, { checked: checked, isIntermediate: isIntermediate, onChange: onChange })), _jsxs("div", { className: classNames(styles.content, center && styles.center, right && styles.right), children: [_jsx(Paragraph, { strong: true, children: children }), tooltip && (_jsx(Tooltip, { title: tooltip, position: tooltipPosition || 'top', children: _jsx(IconInfoCircle, { size: "standard" }) }))] }), isSortable && (_jsx("div", { onClick: onClick, className: classNames(styles['sort-arrow'], isSelected && styles['sort-arrow--sorted']), children: sortIcon }))] }), _jsx("div", { className: classNames(styles.subheader, edgeSpacedSubHeaders && styles['space-between']), children: subHeaders === null || subHeaders === void 0 ? void 0 : subHeaders.map((subHeader) => _jsx(Paragraph, { children: subHeader }, subHeader)) })] }));
}
