import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Page, PageBody, PageHeader } from '@gordon/web-ui';
import { useMemo, useState } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { DeliveryGroups } from './DeliveryGroups';
import { AccountInvoices } from './AccountInvoices';
import { useFetchClientsByInvoiceAccount, useFetchInvoiceAccount, } from '../../api';
import { AccountOverviewCard } from '../../components/AccountOverviewCard';
import { AddAccountNotesModal } from '../../modals/AddAccountNotesModal';
import { UpdateAccountDetailsModal } from '../../modals/UpdateAccountDetailsModal';
import { RateCards } from './RateCards';
export const Account = () => {
    var _a;
    const [showAddAccountNoteModal, setShowAddAccountNoteModal] = useState();
    const [showUpdateAccountDetailsModal, setShowUpdateAccountDetailsModal] = useState();
    const { id } = useParams();
    const { data: account, runRequest } = useFetchInvoiceAccount(id);
    const { data, runRequest: runFetchClients } = useFetchClientsByInvoiceAccount(id);
    const clientOptions = useMemo(() => data === null || data === void 0 ? void 0 : data.map(({ _id, name }) => ({
        value: _id,
        text: name,
    })), [data]);
    const tabs = [
        {
            value: 'clients',
            text: 'Delivery Groups',
            href: `clients`,
        },
        {
            value: 'invoices',
            text: 'Invoices',
            href: `invoices`,
        },
        {
            value: 'rate-cards',
            text: 'Rate cards',
            href: `rate-cards`,
        },
    ];
    const breadcrumbsPaths = [
        {
            path: `/accounts`,
            breadcrumb: 'Accounts',
        },
    ];
    return (_jsxs(Page, { children: [_jsx(PageHeader, { headingText: (_a = account === null || account === void 0 ? void 0 : account.name) !== null && _a !== void 0 ? _a : 'Loading', breadcrumbs: breadcrumbsPaths, allowCurrent: true, tabs: tabs, action: account && (_jsx(AccountOverviewCard, { isActive: account === null || account === void 0 ? void 0 : account.isActive, splitInvoiceSpecByMonth: account === null || account === void 0 ? void 0 : account.splitInvoiceSpecByMonth, periodicity: account === null || account === void 0 ? void 0 : account.periodicity, note: account === null || account === void 0 ? void 0 : account.note, showAddAccountNoteModal: () => setShowAddAccountNoteModal(account), showUpdateAccountDetailsModal: () => setShowUpdateAccountDetailsModal(account) })) }), _jsx(PageBody, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Navigate, { to: "clients", replace: true }) }), _jsx(Route, { path: "clients", element: _jsx(DeliveryGroups, { invoiceAccountId: id !== null && id !== void 0 ? id : '', data: data !== null && data !== void 0 ? data : [], runRequest: runFetchClients }) }), _jsx(Route, { path: "invoices", element: _jsx(AccountInvoices, { clientOptions: clientOptions !== null && clientOptions !== void 0 ? clientOptions : [] }) }), _jsx(Route, { path: "rate-cards", element: _jsx(RateCards, { clientOptions: clientOptions !== null && clientOptions !== void 0 ? clientOptions : [] }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/accounts", replace: true }) })] }) }), showAddAccountNoteModal && account && (_jsx(AddAccountNotesModal, { account: account, onSubmit: () => {
                    runRequest();
                    setShowAddAccountNoteModal(null);
                }, onClose: () => setShowAddAccountNoteModal(null) })), showUpdateAccountDetailsModal && (_jsx(UpdateAccountDetailsModal, { account: showUpdateAccountDetailsModal, onSubmit: () => {
                    runRequest();
                    setShowUpdateAccountDetailsModal(null);
                }, onClose: () => setShowUpdateAccountDetailsModal(null) }))] }));
};
