import { jsx as _jsx } from "react/jsx-runtime";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DateRangePickerWrapper.scss';
import { InputLabels } from '../InputLabels/InputLabels';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useMediaQuery } from 'react-responsive';
import { TABLET_WIDTH, eventBlur } from '../utils/helperFunctions';
import { IconCalendar } from '../Icon';
export function DateRangePickerWrapper({ value = [null, null], onChange, disabled = false, rangeDivider = '-', dayPlaceholder = 'dd', monthPlaceholder = 'mm', yearPlaceholder = 'yyyy', clearIcon = null, calendarIcon = _jsx(IconCalendar, { color: "subtle" }), label = '', hint = '', errorMessage = '', minDate, }) {
    const hasValue = !!value[0] || !!value[1];
    const isMobileOrTablet = useMediaQuery({
        query: `(max-width: ${TABLET_WIDTH})`,
    });
    return (_jsx(InputLabels, { label: label, hint: hint, errorMessage: errorMessage, children: _jsx("div", { className: 'date-range-picker__wrapper', children: _jsx(DateRangePicker, { onFocus: isMobileOrTablet
                    ? eventBlur // on focus event is not very well typed in the range picker
                    : undefined, disabled: disabled, value: value, onChange: onChange, format: 'y/MM/dd', rangeDivider: rangeDivider, dayPlaceholder: dayPlaceholder, monthPlaceholder: monthPlaceholder, yearPlaceholder: yearPlaceholder, clearIcon: hasValue ? clearIcon : null, calendarIcon: calendarIcon, minDate: minDate }) }) }));
}
