import { jsx as _jsx } from "react/jsx-runtime";
import DatePicker from 'react-date-picker';
import './DatePickerWrapper.scss';
import { InputLabels } from '../InputLabels/InputLabels';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { eventBlur } from '../utils/helperFunctions';
import { useDeviceWidth } from '../utils/hooks';
import { IconCalendar } from '../Icon';
export function DatePickerWrapper({ value, onChange, required = false, className, disableCalendar = false, disabled = false, clearIcon = null, yearPlaceholder = 'yyyy', dayPlaceholder = 'dd', monthPlaceholder = 'mm', calendarIcon = _jsx(IconCalendar, { color: "subtle" }), label = '', hint = '', errorMessage = '', }) {
    const { isDesktop } = useDeviceWidth();
    return (_jsx(InputLabels, { label: label, hint: hint, errorMessage: errorMessage, children: _jsx("div", { className: 'date-picker__wrapper', children: _jsx(DatePicker, { onFocus: !isDesktop ? eventBlur : undefined, value: value, onChange: onChange, required: required, className: className, disableCalendar: disableCalendar, disabled: disabled, clearIcon: clearIcon, format: 'y/MM/dd', yearPlaceholder: yearPlaceholder, dayPlaceholder: dayPlaceholder, monthPlaceholder: monthPlaceholder, calendarIcon: calendarIcon }) }) }));
}
