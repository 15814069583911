import { constructApi } from '../../utils/createApi';
import { createArticle } from './article/createArticle';
import { updateArticle } from './article/updateArticle';
import { createRateCard } from './rate-card/createRateCard';
import { updateRateCard } from './rate-card/updateRateCard';
export const createBillingApi = constructApi({
    article: {
        createArticle,
        updateArticle,
    },
    rateCard: {
        createRateCard,
        updateRateCard,
    },
});
