import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DateRangePickerWrapper, IconCalendar, LoadingSpinner, PageSection, Pagination, } from '@gordon/web-ui';
import { useFetchCurrencyOptions, useFetchInvoices } from '../../api';
import { InvoicesTable } from '../../components/InvoicesTable';
import { useParams } from 'react-router-dom';
import { FilterBar } from '../../components/FilterBar';
import { SearchInput } from '../../components/SearchInput';
import dayjs from 'dayjs';
import { FilterDropdown } from '../../components/FilterDropdown';
import { invoicePeriodOptions, invoiceStatusOptions } from '../../utils/options';
export const AccountInvoices = ({ clientOptions, }) => {
    const { id } = useParams();
    const { data, page, pageSize, startDate, endDate, invoiceStatuses, clientIds, periodicity, currency, setParamState, setPage, setPageSize, changeSorting, sortOrder, sortField, totalInvoices, numberOfPages, searchString, isLoading, hasFiltersChanged, clearAllFilters, refresh, } = useFetchInvoices({ accountId: id, clientId: undefined });
    const currencyOptions = useFetchCurrencyOptions();
    return (_jsx(PageSection, { size: "h2", actionsLeft: _jsxs(FilterBar, { clear: hasFiltersChanged ? clearAllFilters : undefined, children: [_jsx(SearchInput, { placeholder: "Search invoice ref.", defaultValue: searchString, onEnter: (search) => {
                        setParamState('searchString', search);
                    } }), _jsx(DateRangePickerWrapper, { clearIcon: null, calendarIcon: IconCalendar, value: [startDate, endDate], minDate: dayjs().subtract(10, 'year').toDate(), onChange: (value) => {
                        const [startDate, endDate] = Array.isArray(value) ? value : [];
                        if (startDate && endDate) {
                            setParamState('startDate', dayjs(startDate).toISOString());
                            setParamState('endDate', dayjs(endDate).toISOString());
                        }
                    } }), _jsx(FilterDropdown, { width: 168, withSearch: true, multiselect: true, options: clientOptions, placeholder: "Delivery groups", value: clientIds, onUpdate: (value) => setParamState('clientIds', value !== null && value !== void 0 ? value : []) }), _jsx(FilterDropdown, { options: currencyOptions, placeholder: "Currency", value: currency, onUpdate: (value) => setParamState('currency', value !== null && value !== void 0 ? value : '') }), _jsx(FilterDropdown, { options: invoicePeriodOptions, placeholder: "Invoice period", value: periodicity, onUpdate: (value) => setParamState('periodicity', value !== null && value !== void 0 ? value : '') }), _jsx(FilterDropdown, { width: 148, multiselect: true, options: invoiceStatusOptions, placeholder: "Invoice status", value: invoiceStatuses, onUpdate: (value) => setParamState('invoiceStatuses', value !== null && value !== void 0 ? value : []) })] }), children: isLoading && !data ? (_jsx(LoadingSpinner, {})) : (_jsxs(_Fragment, { children: [_jsx(InvoicesTable, { data: data !== null && data !== void 0 ? data : [], pageSize: pageSize, setPageSize: setPageSize, totalInvoices: totalInvoices !== null && totalInvoices !== void 0 ? totalInvoices : 0, sortOrder: sortOrder, sortField: sortField, changeSorting: changeSorting, isAccountSpecific: !!id, refreshPage: refresh }), !!numberOfPages && (_jsx(Pagination, { currentPage: page, pages: numberOfPages, onChange: setPage }))] })) }));
};
