import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Page, PageSection, Table, TableHead, HeaderColumn, TableBody, Row, Column, Button, MeatballMenu, MenuListItem, IconEdit1, IconTrash, WeekdayPicker, } from '@gordon/web-ui';
import { useMemo, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { CreateArticleModal } from '../modals/CreateArticleModal';
import { useFetchArticles } from '../api';
import { FilterBar } from '../components/FilterBar';
import { SearchInput } from '../components/SearchInput';
import { FilterDropdown } from '../components/FilterDropdown';
import { useQueryParamState } from '../hooks/useQueryParamState';
import { useSort } from '../hooks/useSort';
import { useSearch } from '../hooks/useSearch';
import { useFilter } from '../hooks/useFilter';
import { prettifyCamelCaseText } from '../utils/helpers';
import { EditXledgerNumberModal } from '../modals/EditXledgerNumberModal';
import { DeleteArticleModal } from '../modals/DeleteArticleModal';
export const Articles = () => {
    const [state, setState, hasChanged, clearAll] = useQueryParamState({
        search: '',
        type: '',
        calculate: '',
    });
    const [showCreateArticleModal, setShowCreateArticleModal] = useState(false);
    const [showEditXledgerNumberModal, setShowEditXledgerNumberModal] = useState();
    const [showDeleteArticleModal, setShowDeleteArticleModal] = useState(null);
    const { data, isLoading, runRequest } = useFetchArticles();
    const articles = data === null || data === void 0 ? void 0 : data.filter(({ deleted }) => !deleted);
    const feeTypeOptions = useMemo(() => {
        var _a;
        return _uniqBy((_a = articles === null || articles === void 0 ? void 0 : articles.filter(({ type }) => type)) === null || _a === void 0 ? void 0 : _a.map(({ type }) => ({
            text: type,
            value: type,
        })), 'value');
    }, [articles]);
    const calculationOptions = useMemo(() => {
        var _a;
        return _uniqBy((_a = articles === null || articles === void 0 ? void 0 : articles.filter(({ calculate }) => calculate)) === null || _a === void 0 ? void 0 : _a.map(({ calculate }) => ({
            text: prettifyCamelCaseText(calculate),
            value: calculate,
        })), 'value');
    }, [articles]);
    const searchedList = useSearch(articles, ['name', 'type', 'calculate', 'description', 'externalId'], state.search);
    const filteredList = useFilter(searchedList, {
        type: state.type,
        calculate: state.calculate,
    });
    const { sortedList: articlesSorted, sortField, sortOrder, changeSorting } = useSort(filteredList);
    return (_jsxs(Page, { children: [_jsx(PageSection, { size: "h1", headingText: "Articles", supportingText: "Articles are set objects that can be inserted into invoice and rate cards.", actionsLeft: _jsxs(FilterBar, { clear: hasChanged ? clearAll : undefined, children: [_jsx(SearchInput, { placeholder: "Search", defaultValue: state.search, onEnter: (value) => setState('search', value) }), _jsx(FilterDropdown, { options: feeTypeOptions, placeholder: "Fee type", value: state.type, onUpdate: (value) => setState('type', value !== null && value !== void 0 ? value : '') }), _jsx(FilterDropdown, { options: calculationOptions, placeholder: "Calculation type", value: state.calculate, onUpdate: (value) => setState('calculate', value !== null && value !== void 0 ? value : '') })] }), actionsRight: _jsx(Button, { variant: "primary", text: "Create article", onClick: () => setShowCreateArticleModal(true) }), children: _jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { title: isLoading
                                ? 'Loading'
                                : `${articlesSorted === null || articlesSorted === void 0 ? void 0 : articlesSorted.length} article${(articlesSorted === null || articlesSorted === void 0 ? void 0 : articlesSorted.length) === 1 ? '' : 's'}`, children: [_jsx(HeaderColumn, { width: '20cqw', isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'name', onClick: () => changeSorting('name'), children: "Article name" }), _jsx(HeaderColumn, { width: '25cqw', children: "Description" }), _jsx(HeaderColumn, { width: '15cqw', children: "Weekdays" }), _jsx(HeaderColumn, { children: "Timewindow (hrs) " }), _jsx(HeaderColumn, { isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'prePick', onClick: () => changeSorting('prePick'), children: "Pre picked" }), _jsx(HeaderColumn, { width: '15cqw', isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'type', onClick: () => changeSorting('type'), children: "Fee type" }), _jsx(HeaderColumn, { width: '15cqw', isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'calculate', onClick: () => changeSorting('calculate'), children: "Calculation type" }), _jsx(HeaderColumn, { width: '15cqw', children: "Xledger article number" }), _jsx(HeaderColumn, { width: 48 })] }), _jsx(TableBody, { children: articlesSorted && (articlesSorted === null || articlesSorted === void 0 ? void 0 : articlesSorted.length) < 1 ? (_jsx(Row, { children: _jsx(Column, { span: 100, text: "No articles found" }) })) : (articlesSorted === null || articlesSorted === void 0 ? void 0 : articlesSorted.map((article) => (_jsxs(Row, { children: [_jsx(Column, { text: article.name }), _jsx(Column, { text: article.description }), _jsx(Column, { children: _jsx(WeekdayPicker, { pickedDays: article.weekdays && article.weekdays.length > 0
                                                ? article.weekdays
                                                : [1, 2, 3, 4, 5, 6, 7] }) }), _jsx(Column, { text: article.timeWindowLengths && article.timeWindowLengths.length > 0
                                            ? article.timeWindowLengths.map((tw) => tw).join(', ')
                                            : 'All hours' }), _jsx(Column, { text: article.prePick === undefined ? 'Any' : article.prePick ? 'Yes' : 'No' }), _jsx(Column, { text: article.type }), _jsx(Column, { text: prettifyCamelCaseText(article.calculate) }), _jsx(Column, { text: article.externalId }), _jsx(Column, { children: _jsx("span", { style: {
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }, children: _jsxs(MeatballMenu, { variant: "tertiary", children: [_jsx(MenuListItem, { leadingElement: _jsx(IconEdit1, {}), value: "Edit Xledger article number", text: "Edit Xledger article number", onClick: () => setShowEditXledgerNumberModal(article) }), _jsx(MenuListItem, { leadingElement: _jsx(IconTrash, { color: "error" }), destructive: true, value: "Delete article", text: "Delete article", onClick: () => setShowDeleteArticleModal(article._id) })] }) }) })] }, article._id)))) })] }) }), showCreateArticleModal && (_jsx(CreateArticleModal, { onSubmit: () => {
                    runRequest();
                    setShowCreateArticleModal(false);
                }, onClose: () => setShowCreateArticleModal(false) })), showEditXledgerNumberModal && (_jsx(EditXledgerNumberModal, { id: showEditXledgerNumberModal._id, externalId: showEditXledgerNumberModal.externalId, onSubmit: () => {
                    runRequest();
                    setShowEditXledgerNumberModal(null);
                }, onClose: () => setShowEditXledgerNumberModal(null) })), showDeleteArticleModal && (_jsx(DeleteArticleModal, { id: showDeleteArticleModal, onSubmit: () => {
                    runRequest();
                    setShowDeleteArticleModal(null);
                }, onClose: () => setShowDeleteArticleModal(null) }))] }));
};
