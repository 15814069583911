import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Table, TableBody, TableHead, Column, HeaderColumn, Button, IconEdit1, } from '@gordon/web-ui';
import { useEffect, useState } from 'react';
import { PageSizeSelector } from './PageSizeSelector';
import { addOrRemove, amount, formatDate } from '../utils/helpers';
import { StatusBar } from './StatusBar';
import { UpdateStatusModal } from '../modals/UpdateStatusModal';
export const InvoicesTable = ({ data, pageSize, setPageSize, totalInvoices, sortOrder, sortField, changeSorting, refreshPage, isAccountSpecific = false, isClientSpecific = false, }) => {
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
    const allSelected = !!data.length && selectedInvoices.length === data.length;
    useEffect(() => {
        setSelectedInvoices([]);
    }, [data]);
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { title: selectedInvoices.length > 0
                            ? `${selectedInvoices.length} selected`
                            : totalInvoices !== undefined
                                ? totalInvoices > 1
                                    ? `${totalInvoices} invoices`
                                    : `${totalInvoices} invoice`
                                : 'Loading', actions: _jsxs(_Fragment, { children: [selectedInvoices.length > 0 && (_jsx(Button, { icon: IconEdit1, variant: "secondary", size: "small", onClick: () => setShowUpdateStatusModal(true), text: "Update status" })), _jsx(PageSizeSelector, { pageSize: pageSize, setPageSize: setPageSize })] }), children: [_jsx(HeaderColumn, { checked: allSelected, onChange: () => setSelectedInvoices(allSelected ? [] : data.map(({ _id }) => _id)), width: 150, children: "Invoice ref." }), !isAccountSpecific && !isClientSpecific && _jsx(HeaderColumn, { children: "Account name" }), !isClientSpecific && _jsx(HeaderColumn, { children: "Delivery group" }), _jsx(HeaderColumn, { children: "Rate card" }), _jsx(HeaderColumn, { isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'periodicity', onClick: () => changeSorting('periodicity'), width: isClientSpecific ? undefined : 104, children: "Invoice period" }), _jsx(HeaderColumn, { isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'startDate', onClick: () => changeSorting('startDate'), width: isClientSpecific ? undefined : 175, children: "Date range" }), _jsx(HeaderColumn, { width: 140, right: true, children: "Total price" }), _jsx(HeaderColumn, { isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'status', onClick: () => changeSorting('status'), width: 155, children: "Invoice status" })] }), _jsx(TableBody, { children: data.length ? (data.map((invoice) => {
                            var _a, _b, _c, _d;
                            return (_jsxs(Row, { selected: selectedInvoices.includes(invoice._id), children: [_jsx(Column, { checked: selectedInvoices.includes(invoice._id), onChange: () => setSelectedInvoices(addOrRemove(selectedInvoices, invoice._id)), text: invoice.invoiceNumber, href: `/invoices/${invoice._id}` }), !isAccountSpecific && !isClientSpecific && (_jsx(Column, { text: invoice.invoiceAccount.name, href: `/accounts/${invoice.invoiceAccount._id}` })), !isClientSpecific && (_jsx(Column, { text: (_a = invoice.invoiceClient) === null || _a === void 0 ? void 0 : _a.name, href: `/clients/${(_b = invoice.invoiceClient) === null || _b === void 0 ? void 0 : _b._id}` })), _jsx(Column, { text: (_c = invoice.rateCard) === null || _c === void 0 ? void 0 : _c.name, href: `/rate-cards/${(_d = invoice.rateCard) === null || _d === void 0 ? void 0 : _d._id}` }), _jsx(Column, { text: invoice.periodicity }), _jsx(Column, { text: `${formatDate(invoice.startDate)} - ${formatDate(invoice.endDate)}` }), _jsx(Column, { right: true, text: amount(invoice.totalAmount, invoice.currency) }), _jsx(Column, { children: _jsx(StatusBar, { status: invoice.status }) })] }, invoice._id));
                        })) : (_jsx(Row, { children: _jsx(Column, { span: 100, text: "No invoices found" }) })) })] }), showUpdateStatusModal && (_jsx(UpdateStatusModal, { invoiceIds: selectedInvoices, onSubmit: () => {
                    refreshPage();
                    setSelectedInvoices([]);
                    setShowUpdateStatusModal(false);
                }, onClose: () => setShowUpdateStatusModal(false) }))] }));
};
