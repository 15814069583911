import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Select, Input, Modal, ModalRow, ModalSection, Textarea, } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAddArticle, useFetchCalculationOptions } from '../api';
export function CreateArticleModal({ onSubmit, onClose, }) {
    const { register, watch, setValue, handleSubmit } = useForm();
    const calculationOptions = useFetchCalculationOptions();
    const { isLoading, isSuccess, error, runRequest } = useAddArticle();
    const name = watch('name');
    const type = watch('type');
    const calculate = watch('calculate');
    useEffect(() => {
        register('calculate');
    }, []);
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    const submitHandler = ({ name, type, description, calculate, externalId, }) => {
        runRequest({
            name: name,
            type: type,
            description: description,
            calculate: calculate,
            externalId: externalId,
        });
    };
    return (_jsxs(Modal, { title: "Create article", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", disabled: !name || !type || !calculate || isLoading, isLoading: isLoading, onClick: handleSubmit(submitHandler), text: 'Save' })] }), children: [_jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Input, Object.assign({}, register('name'), { label: "Article name", placeholder: "Add name" })) }), _jsx(ModalRow, { children: _jsx(Input, Object.assign({}, register('type'), { label: "Fee type", placeholder: "Add fee type" })) }), _jsx(ModalRow, { children: _jsx(Textarea, Object.assign({}, register('description'), { label: "Description", placeholder: "Add description", maxLength: 250, height: 56 })) }), _jsx(ModalRow, { children: _jsx(Select, { label: "Calculation type", placeholder: "Select", options: calculationOptions, value: calculate, onSelect: (val) => val && setValue('calculate', val) }) }), _jsx(ModalRow, { children: _jsx(Input, Object.assign({}, register('externalId'), { type: "number", label: "Xledger article number", placeholder: "Add number" })) })] }), error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) }))] }));
}
