import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Select, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateInvoiceStatus } from '../api';
import { invoiceStatusOptions } from '../utils/options';
export function UpdateStatusModal({ invoiceIds, onSubmit, onClose, }) {
    var _a;
    const { register, watch, setValue, resetField, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            invoiceIds,
        },
    });
    const { isLoading, runRequest, error, isSuccess } = useUpdateInvoiceStatus();
    useEffect(() => {
        register('status', { required: 'Status is required' });
    }, []);
    const status = watch('status');
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    return (_jsx(Modal, { title: "Update status", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: "Update", isLoading: isLoading, disabled: !status || isLoading, onClick: handleSubmit(runRequest) })] }), children: _jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Select, { placeholder: "Select", label: "Invoice status", options: invoiceStatusOptions, value: status, onSelect: (newStatus) => newStatus ? setValue('status', newStatus) : resetField('status'), errorMessage: (_a = errors.status) === null || _a === void 0 ? void 0 : _a.message }) }), error && _jsx(Alert, { variant: "error", heading: "Something went wrong" })] }) }));
}
