import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Select, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateInvoiceAccount } from '../api';
import { invoicePeriodOptions, monthlySplitOptions, statusOptions } from '../utils/options';
export function UpdateAccountDetailsModal({ account, onSubmit, onClose, }) {
    const { register, watch, setValue, handleSubmit } = useForm({
        defaultValues: {
            _id: account._id,
            isActive: account.isActive,
            periodicity: account.periodicity,
            splitInvoiceSpecByMonth: account.splitInvoiceSpecByMonth,
        },
    });
    const { isLoading, runRequest, error, isSuccess } = useUpdateInvoiceAccount();
    useEffect(() => {
        register('isActive');
        register('splitInvoiceSpecByMonth');
        register('periodicity', { required: true });
    }, []);
    const isActive = watch('isActive');
    const splitInvoiceSpecByMonth = watch('splitInvoiceSpecByMonth');
    const periodicity = watch('periodicity');
    const hasChanges = isActive !== account.isActive ||
        periodicity !== account.periodicity ||
        splitInvoiceSpecByMonth !== account.splitInvoiceSpecByMonth;
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    return (_jsx(Modal, { title: "Edit account details", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: "Save", isLoading: isLoading, disabled: !hasChanges, onClick: handleSubmit(runRequest) })] }), children: _jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Select, { label: "Account status", options: statusOptions, allowUnselect: false, value: isActive ? 'active' : 'inactive', onSelect: (newStatus) => setValue('isActive', newStatus === 'active' ? true : false) }) }), _jsxs(ModalRow, { children: [_jsx(Select, { label: "Monthly split", options: monthlySplitOptions, allowUnselect: false, value: splitInvoiceSpecByMonth ? 'true' : 'false', onSelect: (split) => setValue('splitInvoiceSpecByMonth', split === 'true' ? true : false) }), _jsx(Select, { label: "Invoice period", options: invoicePeriodOptions, allowUnselect: false, value: periodicity, onSelect: (newPeriod) => newPeriod && setValue('periodicity', newPeriod) })] }), error && _jsx(Alert, { variant: "error", heading: "Something went wrong" })] }) }));
}
