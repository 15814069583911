import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './App.scss';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Accounts } from './pages/Accounts';
import { Layout } from './components/Layout';
import { Invoices } from './pages/Invoices';
import { Invoice } from './pages/Invoice';
import { Account } from './pages/Account';
import { DeliveryGroup } from './pages/DeliveryGroup';
import { Articles } from './pages/Articles';
import { RateCard } from './pages/RateCard';
import { isTokenExpired, removeToken } from './auth/authApi';
function Logout() {
    removeToken();
    return _jsx(Navigate, { to: "/login" });
}
const AuthenticatedApp = () => {
    if (isTokenExpired()) {
        sessionStorage.setItem('lastKnownPath', window.location.pathname);
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    return (_jsx(Layout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/accounts", element: _jsx(Accounts, {}) }), _jsx(Route, { path: "/accounts/:id/*", element: _jsx(Account, {}) }), _jsx(Route, { path: "/rate-cards/:id/*", element: _jsx(RateCard, {}) }), _jsx(Route, { path: "/invoices", element: _jsx(Invoices, {}) }), _jsx(Route, { path: "/invoices/:id", element: _jsx(Invoice, {}) }), _jsx(Route, { path: "/articles", element: _jsx(Articles, {}) }), _jsx(Route, { path: "/clients/:id", element: _jsx(DeliveryGroup, {}) }), _jsx(Route, { path: "/logout", element: _jsx(Logout, {}) }), _jsx(Route, { path: "/", element: _jsx(Navigate, { to: "/accounts", replace: true }) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }) }));
};
export const App = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "*", element: _jsx(AuthenticatedApp, {}) })] }));
};
