import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Column, HeaderColumn, PageSection, Row, Table, TableBody, TableHead, } from '@gordon/web-ui';
import { amount } from '../../utils/helpers';
export const Exceptions = ({ exceptions, currency, formatItemsPerOrderText }) => {
    if (!exceptions || exceptions.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(PageSection, { size: "h2", headingText: "Exceptions", supportingText: "These prices will be used instead of the default prices for the selected depots" }), exceptions.length > 0 &&
                exceptions.map((exception, index) => (_jsx(PageSection, { size: "h3", headingText: exception.depots.map(({ name }) => name).join(', '), children: _jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { children: [_jsx(HeaderColumn, { width: '30cqw', children: "Article name" }), exception.thresholds.map((threshold) => (_jsxs(HeaderColumn, { width: '50cqw', right: true, children: ["Threshold: ", threshold.threshold] }, threshold.threshold)))] }), _jsx(TableBody, { children: exception.thresholds[0].rates
                                    .map((rate) => rate.article)
                                    .map((article) => (_jsxs(Row, { children: [_jsx(Column, { text: article.name, supportText: article.calculate === 'item' ? '↳ Items per order' : undefined }), exception.thresholds.map((threshold) => {
                                            var _a, _b, _c;
                                            return (_jsx(Column, { right: true, text: amount((_a = threshold.rates.find((rate) => rate.article._id === article._id)) === null || _a === void 0 ? void 0 : _a.price, currency), supportText: article.calculate === 'item' ? (_jsx("div", { style: { textAlign: 'right' }, children: formatItemsPerOrderText((_b = threshold.rates.find((rate) => rate.article._id === article._id)) === null || _b === void 0 ? void 0 : _b.includedQuantity) })) : undefined }, (_c = threshold.rates.find((rate) => rate.article._id === article._id)) === null || _c === void 0 ? void 0 : _c._id));
                                        })] }, article._id))) })] }) }, index)))] }));
};
