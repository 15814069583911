import { jsx as _jsx } from "react/jsx-runtime";
import { IconSearchLg, Input } from '@gordon/web-ui';
import React, { useEffect } from 'react';
export const SearchInput = ({ defaultValue = '', placeholder, onEnter, }) => {
    const [search, setSearch] = React.useState(defaultValue);
    useEffect(() => {
        if (defaultValue !== search)
            setSearch(defaultValue);
    }, [defaultValue]);
    return (_jsx("div", { style: { width: 220 }, children: _jsx(Input, { icon: IconSearchLg, placeholder: placeholder, value: search, onChange: (e) => setSearch(e.target.value), onKeyDown: (e) => {
                if (e.code === 'Enter') {
                    onEnter(search);
                }
            } }) }));
};
