import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { IconChevronRight } from '../Icon';
import styles from './Breadcrumb.module.scss';
export function Breadcrumb({ breadcrumbs, onClick, allowCurrent, underline }) {
    function isCurrent(index) {
        return index === (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) - 1;
    }
    return (_jsx("nav", { "aria-label": "Breadcrumb", className: styles.base, children: breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.map((bc, i) => {
            const disabled = isCurrent(i) && !allowCurrent;
            return (_jsx("div", { className: styles.item, children: disabled ? (_jsx("span", { children: bc.breadcrumb })) : (_jsxs(_Fragment, { children: [_jsx(Link, { to: bc.path, onClick: onClick, className: underline === true ? styles.item__underlineLink : '', children: bc.breadcrumb }), _jsx(IconChevronRight, { size: "small", color: "subtle" })] })) }, i));
        }) }));
}
