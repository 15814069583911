import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Page, PageBody, PageHeader } from '@gordon/web-ui';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useFetchRateCard } from '../../api';
import { Overview } from './Overview';
import { ChangeLogs } from './ChangeLogs';
export const RateCard = () => {
    var _a;
    const { id } = useParams();
    const { data: rateCard, setData } = useFetchRateCard(id);
    const breadcrumbsPaths = [
        {
            path: `/accounts`,
            breadcrumb: 'Accounts',
        },
        {
            path: `/accounts/${rateCard === null || rateCard === void 0 ? void 0 : rateCard.invoiceAccount._id}`,
            breadcrumb: `${rateCard === null || rateCard === void 0 ? void 0 : rateCard.invoiceAccount.name}`,
        },
        {
            path: `/accounts/${rateCard === null || rateCard === void 0 ? void 0 : rateCard.invoiceAccount._id}/rate-cards`,
            breadcrumb: 'Rate cards',
        },
    ];
    const tabs = [
        {
            value: 'overview',
            text: 'Overview',
            href: `overview`,
        },
        {
            value: 'change-log',
            text: 'Change log',
            href: `change-log`,
        },
    ];
    return (_jsxs(Page, { children: [_jsx(PageHeader, { breadcrumbs: breadcrumbsPaths, allowCurrent: true, headingText: (_a = rateCard === null || rateCard === void 0 ? void 0 : rateCard.name) !== null && _a !== void 0 ? _a : 'Loading', tabs: tabs }), _jsx(PageBody, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Navigate, { to: "overview", replace: true }) }), _jsx(Route, { path: "change-log", element: _jsx(ChangeLogs, { rateCard: rateCard }) }), _jsx(Route, { path: "overview", element: _jsx(Overview, { rateCard: rateCard, setData: setData }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/accounts", replace: true }) })] }) })] }));
};
