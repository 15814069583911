import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Select, Input, Modal, ModalRow, ModalSection, Textarea, } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetchCalculationOptions } from '../api';
import { usePromise } from '@gordon/frontend/usePromise';
import { api } from '../newApi';
export const timeWindowLengthsOptions = [
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: '6', text: '6' },
    { value: '7', text: '7' },
    { value: '8', text: '8' },
    { value: '9', text: '9' },
    { value: '10', text: '10' },
    { value: '11', text: '11' },
    { value: '12', text: '12' },
    { value: '13', text: '13' },
    { value: '14', text: '14' },
    { value: '15', text: '15' },
    { value: '16', text: '16' },
    { value: '17', text: '17' },
    { value: '18', text: '18' },
    { value: '19', text: '19' },
    { value: '20', text: '20' },
    { value: '21', text: '21' },
    { value: '22', text: '22' },
    { value: '23', text: '23' },
    { value: '24', text: '24' },
];
export const weekdaysOptions = [
    { value: '1', text: 'Mon' },
    { value: '2', text: 'Tue' },
    { value: '3', text: 'Wed' },
    { value: '4', text: 'Thu' },
    { value: '5', text: 'Fri' },
    { value: '6', text: 'Sat' },
    { value: '7', text: 'Sun' },
];
export const prePickOptions = [
    { value: 'true', text: 'Yes' },
    { value: 'false', text: 'No' },
    { value: 'undefined', text: 'Any' },
];
export function CreateArticleModal({ onSubmit, onClose, }) {
    var _a, _b;
    const { register, watch, setValue, handleSubmit, getValues } = useForm();
    const calculationOptions = useFetchCalculationOptions();
    const { isLoading, isSuccess, error, run } = usePromise(api.article.createArticle);
    const name = watch('name');
    const type = watch('type');
    const calculate = watch('calculate');
    const weekdays = watch('weekdays');
    const timeWindowLengths = watch('timeWindowLengths');
    const prePick = watch('prePick');
    useEffect(() => {
        register('calculate');
    }, []);
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    const submitHandler = ({ name, type, description, calculate, externalId, timeWindowLengths, prePick, weekdays, }) => {
        run({
            name: name,
            type: type,
            description: description,
            calculate: calculate,
            externalId: externalId,
            timeWindowLengths: timeWindowLengths,
            prePick: prePick,
            weekdays: weekdays,
        });
    };
    return (_jsxs(Modal, { title: "Create article", onClose: onClose, size: "medium", footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", disabled: !name || !type || !calculate || isLoading, isLoading: isLoading, onClick: handleSubmit(submitHandler), text: 'Save' })] }), children: [_jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Input, Object.assign({}, register('name'), { label: "Article name", placeholder: "Add name" })) }), _jsx(ModalRow, { children: _jsx(Textarea, Object.assign({}, register('description'), { label: "Article description", placeholder: "Add description", maxLength: 250, height: 56 })) }), _jsxs(ModalRow, { columns: 2, children: [_jsx(Select, Object.assign({ placeholder: "All days", options: weekdaysOptions, multiselect: true, label: "Weekdays", value: (_a = weekdays === null || weekdays === void 0 ? void 0 : weekdays.map((val) => val.toString())) !== null && _a !== void 0 ? _a : [], onSelect: (val) => {
                                    setValue('weekdays', val.map((v) => Number(v)));
                                } }, register('timeWindowLengths'))), _jsx(Select, Object.assign({ placeholder: "All hours", label: "Time window (hrs)", options: timeWindowLengthsOptions, multiselect: true, value: (_b = timeWindowLengths === null || timeWindowLengths === void 0 ? void 0 : timeWindowLengths.map((val) => val.toString())) !== null && _b !== void 0 ? _b : [], onSelect: (val) => {
                                    setValue('timeWindowLengths', val.map((v) => Number(v)));
                                } }, register('timeWindowLengths')))] }), _jsxs(ModalRow, { columns: 2, children: [_jsx(Select, Object.assign({ placeholder: "Any", label: "Pre-pick", options: prePickOptions, value: prePick === undefined ? 'undefined' : getValues('prePick') ? 'true' : 'false', onSelect: (val) => {
                                    setValue('prePick', val === 'undefined' || val === undefined ? undefined : val === 'true' ? true : false);
                                } }, register('prePick'))), _jsx(Input, Object.assign({}, register('type'), { label: "Fee type", placeholder: "Add fee type" }))] }), _jsxs(ModalRow, { columns: 2, children: [_jsx(Select, { label: "Calculation type", placeholder: "Select", options: calculationOptions, value: calculate, onSelect: (val) => setValue('calculate', val) }), _jsx(Input, Object.assign({}, register('externalId'), { type: "number", label: "Xledger article number", placeholder: "Add number" }))] })] }), error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) }))] }));
}
