import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Modal, ModalSection } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useUpdateArticle } from '../api';
export function DeleteArticleModal({ id, onSubmit, onClose, }) {
    const { isLoading, isSuccess, error, runRequest } = useUpdateArticle(id);
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    return (_jsx(Modal, { title: "Delete article", supportingText: "The action you are about to perform can not be reversed. Are you sure you want to continue?", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "destructive", isLoading: isLoading, onClick: () => runRequest({ deleted: true }), text: "Delete" })] }), children: error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) })) }));
}
