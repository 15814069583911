export const toKeys = (o) => Object.keys(o);
export const toValues = (o) => Object.values(o);
export const toEntries = (o) => Object.entries(o);
export const removeKey = (obj, key) => {
    const newObj = Object.assign({}, obj);
    if (newObj[key])
        delete newObj[key];
    return newObj;
};
export function isKeyOfObject(key, obj) {
    return key in obj;
}
export const isDefined = (value) => {
    return value !== undefined && value !== null;
};
export const isTruthy = (value) => Boolean(value);
