import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { DateRangePickerWrapper, IconCalendar, LoadingSpinner, Page, PageBody, PageHeader, PageSection, Pagination, } from '@gordon/web-ui';
import { useFetchClient, useFetchInvoices, useFetchRateCardsByInvoiceAccount, } from '../api';
import { Section } from '../components/Section';
import { InvoicesTable } from '../components/InvoicesTable';
import { DeliveryGroupOverviewCard } from '../components/DeliveryGroupOverviewCard';
import { useParams } from 'react-router-dom';
import { FilterBar } from '../components/FilterBar';
import { SearchInput } from '../components/SearchInput';
import dayjs from 'dayjs';
import { FilterDropdown } from '../components/FilterDropdown';
import { invoicePeriodOptions, invoiceStatusOptions } from '../utils/options';
import { EditDeliveryGroupModal } from '../modals/EditDeliveryGroupModal';
export const DeliveryGroup = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [showEditDeliveryGroupModal, setShowEditDeliveryGroupModal] = useState();
    const { id } = useParams();
    const { data: clientData, runRequest } = useFetchClient(id);
    const { data: rateCards, runRequest: fetchRateCards } = useFetchRateCardsByInvoiceAccount(clientData === null || clientData === void 0 ? void 0 : clientData.invoiceAccount._id, false);
    const { data, page, pageSize, startDate, endDate, invoiceStatuses, periodicity, setParamState, setPage, setPageSize, changeSorting, sortOrder, sortField, totalInvoices, numberOfPages, searchString, isLoading, hasFiltersChanged, clearAllFilters, refresh, } = useFetchInvoices({ accountId: undefined, clientId: id });
    const breadcrumbsPaths = [
        {
            path: `/accounts`,
            breadcrumb: 'Accounts',
        },
        {
            path: `/accounts/${clientData === null || clientData === void 0 ? void 0 : clientData.invoiceAccount._id}`,
            breadcrumb: (_a = clientData === null || clientData === void 0 ? void 0 : clientData.invoiceAccount.name) !== null && _a !== void 0 ? _a : '',
        },
        {
            path: `/accounts/${(_b = clientData === null || clientData === void 0 ? void 0 : clientData.invoiceAccount) === null || _b === void 0 ? void 0 : _b._id}/clients`,
            breadcrumb: 'Delivery groups',
        },
    ];
    useEffect(() => {
        if (clientData && !rateCards) {
            fetchRateCards();
        }
    }, [clientData, rateCards, fetchRateCards]);
    const rateCardOptions = useMemo(() => rateCards === null || rateCards === void 0 ? void 0 : rateCards.map((rateCard) => ({
        text: rateCard.name,
        value: rateCard.sourceId,
    })), [rateCards]);
    return (_jsxs(Page, { children: [_jsx(PageHeader, { headingText: (_c = clientData === null || clientData === void 0 ? void 0 : clientData.name) !== null && _c !== void 0 ? _c : '', breadcrumbs: breadcrumbsPaths, allowCurrent: true }), _jsxs(PageBody, { children: [_jsx(PageSection, { size: "h2", headingText: "Overview", children: _jsx(DeliveryGroupOverviewCard, { rateCardId: (_e = (_d = clientData === null || clientData === void 0 ? void 0 : clientData.rateCardSourceId) === null || _d === void 0 ? void 0 : _d.sourceId) !== null && _e !== void 0 ? _e : '', rateCardName: (_g = (_f = clientData === null || clientData === void 0 ? void 0 : clientData.rateCardSourceId) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : '', customerID: (_h = clientData === null || clientData === void 0 ? void 0 : clientData.externalCustomerId) !== null && _h !== void 0 ? _h : '', entityID: (_j = clientData === null || clientData === void 0 ? void 0 : clientData.externalLegalEntityId) !== null && _j !== void 0 ? _j : '', periodicity: 'weekly', setShowEditDeliveryGroupModal: () => setShowEditDeliveryGroupModal(clientData) }) }), _jsx(PageSection, { size: "h2", headingText: "Invoices", children: _jsxs(Section, { children: [_jsxs(FilterBar, { clear: hasFiltersChanged ? clearAllFilters : undefined, children: [_jsx(SearchInput, { placeholder: "Search invoice ref.", defaultValue: searchString, onEnter: (search) => {
                                                setParamState('searchString', search);
                                            } }), _jsx(DateRangePickerWrapper, { clearIcon: null, calendarIcon: IconCalendar, value: [startDate, endDate], minDate: dayjs().subtract(10, 'year').toDate(), onChange: (value) => {
                                                const [startDate, endDate] = Array.isArray(value) ? value : [];
                                                if (startDate && endDate) {
                                                    setParamState('startDate', dayjs(startDate).toISOString());
                                                    setParamState('endDate', dayjs(endDate).toISOString());
                                                }
                                            } }), _jsx(FilterDropdown, { options: invoicePeriodOptions, placeholder: "Invoice period", value: periodicity, onUpdate: (value) => setParamState('periodicity', value !== null && value !== void 0 ? value : '') }), _jsx(FilterDropdown, { width: 148, options: invoiceStatusOptions, placeholder: "Invoice status", value: invoiceStatuses, multiselect: true, onUpdate: (value) => setParamState('invoiceStatuses', value !== null && value !== void 0 ? value : []) })] }), isLoading && !data ? (_jsx(LoadingSpinner, {})) : (_jsxs(_Fragment, { children: [_jsx(InvoicesTable, { data: data !== null && data !== void 0 ? data : [], pageSize: pageSize, setPageSize: setPageSize, totalInvoices: totalInvoices !== null && totalInvoices !== void 0 ? totalInvoices : 0, sortOrder: sortOrder, sortField: sortField, changeSorting: changeSorting, isClientSpecific: !!id, refreshPage: refresh }), !!numberOfPages && (_jsx(Pagination, { currentPage: page, pages: numberOfPages, onChange: setPage }))] }))] }) })] }), showEditDeliveryGroupModal && rateCardOptions && (_jsx(EditDeliveryGroupModal, { rateCardOptions: rateCardOptions, client: showEditDeliveryGroupModal, onSubmit: () => {
                    runRequest();
                    setShowEditDeliveryGroupModal(null);
                }, onClose: () => setShowEditDeliveryGroupModal(null) }))] }));
};
