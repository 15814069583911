import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Input, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { AddArticlesTable } from './AddArticleTable';
export const AddArticlesModal = ({ onClose, onAdd, availableArticles, alreadyUsedArticles, }) => {
    const [newArticles, setNewArticles] = useState([]);
    const filteredArticles = availableArticles.filter((a) => !alreadyUsedArticles.find((b) => a._id === b._id));
    const [search, setSearch] = useState('');
    return (_jsx(Modal, { onClose: onClose, title: "Add articles", supportingText: "Select the articles you would like to add into the rate card from the table below.", footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: "Add articles", disabled: newArticles.length === 0, onClick: () => onAdd(newArticles) })] }), children: _jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Input, { onChange: (e) => setSearch(e.target.value), value: search, label: "Article search" }) }), _jsx(ModalRow, { children: _jsx(AddArticlesTable, { articles: newArticles, setArticles: setNewArticles, availableArticles: filteredArticles.filter((a) => a.name.toLowerCase().includes(search.toLowerCase())) }) })] }) }));
};
