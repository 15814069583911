import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from '../../Typography';
import './MenuListItem.scss';
import { classNames, stopPropagation } from '../../utils/helperFunctions';
export function MenuListItem({ value, text, defaultIcon, leadingElement, onClick, disabled, trailingElement, toggle, destructive = false, }) {
    const leading = defaultIcon || leadingElement;
    const gridStyle = leading && trailingElement
        ? 'both'
        : leading
            ? 'leading'
            : trailingElement
                ? 'trailing'
                : undefined;
    const handleClick = (e) => {
        stopPropagation(e);
        toggle && toggle();
        onClick(value);
    };
    const handleKeyDown = (event) => {
        if (event.code === 'Space') {
            event.preventDefault();
            onClick(value);
        }
    };
    return (_jsxs("li", { tabIndex: disabled ? -1 : 0, "aria-disabled": disabled, role: "menuitem", onKeyDown: disabled ? undefined : handleKeyDown, onClick: (e) => (disabled ? null : handleClick(e)), className: classNames('u-ui-dropdown-menu-list-item', gridStyle && `u-ui-dropdown-menu-list-item--${gridStyle}`, destructive && !disabled && 'u-ui-dropdown-menu-list-item--destructive', disabled && 'u-ui-dropdown-menu-list-item--disabled'), children: [leading, _jsx(Paragraph, { color: disabled ? 'disabled' : destructive ? 'error' : 'strong', disabled: disabled, children: text }), trailingElement] }));
}
