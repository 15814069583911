import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import '../DropdownHead/DropdownHead.scss';
import { classNames } from '../utils/helperFunctions';
import { LoadingSpinner } from '../LoadingSpinner';
import { IconXCircle } from '../Icon';
import styles from './Combobox.module.scss';
export const ComboboxHead = React.forwardRef(({ id, placeholder, disabled, error, leadingIcon, onFocus, onBlur, onChange, selected = '', active, value, reset, isLoading, onKeyDown, allowClear = true, }, ref) => {
    return (_jsxs("div", { className: classNames(styles.comboboxHead, error && styles.error, !!leadingIcon && styles.withIcon), ref: ref, children: [leadingIcon, _jsx("input", { id: id, className: styles.input, value: active ? value : selected, onChange: (e) => onChange(e.target.value), onFocus: onFocus, onBlur: onBlur, onKeyDown: onKeyDown, placeholder: selected || placeholder, disabled: disabled }), isLoading ? (_jsx(LoadingSpinner, { size: "standard" })) : allowClear && selected && !disabled ? (_jsx("button", { className: styles.clearButton, type: "button", disabled: disabled, onMouseDown: (e) => {
                    e.preventDefault();
                    reset();
                }, title: "Clear input", name: "clear", tabIndex: -1, children: _jsx(IconXCircle, { color: disabled ? 'disabled' : 'strong' }) })) : null] }));
});
