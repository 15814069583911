import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useRef } from 'react';
import { Heading, Paragraph } from '../Typography';
import { Breadcrumb } from '../Breadcrumb/Breadcrumb';
import styles from './PageHeader.module.scss';
import { Tabs } from '../Tabs';
import { classNames } from '../utils/helperFunctions';
export function PageHeader({ breadcrumbs, tabs, allowCurrent, headingText, supportingText, children, action, }) {
    const ref = useRef(null);
    const hasOnlyHeader = headingText && !breadcrumbs && !tabs && !children && !action;
    const hasNoTabs = !tabs || tabs.length === 0;
    return (_jsxs("div", { className: classNames(styles.base, hasOnlyHeader && styles.onlyHeader, hasNoTabs && styles.noTabs), children: [_jsxs("div", { ref: ref, className: styles.content, children: [breadcrumbs && _jsx(Breadcrumb, { allowCurrent: allowCurrent, breadcrumbs: breadcrumbs }), _jsxs("div", { className: styles.heading, children: [_jsxs("div", { children: [_jsx(Heading, { size: "h1", children: headingText }), supportingText && _jsx(Paragraph, { children: supportingText }), children] }), action] })] }), tabs && _jsx(Tabs, { tabs: tabs })] }));
}
