import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { DeliveryGroupsTable } from './DeliveryGroupsTable';
import { PageSection } from '@gordon/web-ui';
import { useFetchRateCardsByInvoiceAccount } from '../../api';
import { FilterBar } from '../../components/FilterBar';
import { SearchInput } from '../../components/SearchInput';
import { useSearch } from '../../hooks/useSearch';
import { FilterDropdown } from '../../components/FilterDropdown';
import { useFilter } from '../../hooks/useFilter';
import { useQueryParamState } from '../../hooks/useQueryParamState';
export const DeliveryGroups = ({ data, runRequest, invoiceAccountId, }) => {
    const [state, setState, hasChanged, clearAll] = useQueryParamState({
        search: '',
        rateCardIds: new Array(),
    });
    const searchedList = useSearch(data, ['name', 'rateCardSourceId.name', 'externalCustomerId', 'externalLegalEntityId'], state.search);
    const filteredList = useFilter(searchedList, {
        'rateCardSourceId.sourceId': state.rateCardIds,
    });
    const { data: rateCards } = useFetchRateCardsByInvoiceAccount(invoiceAccountId);
    const rateCardOptions = useMemo(() => rateCards === null || rateCards === void 0 ? void 0 : rateCards.map((rateCard) => ({
        text: rateCard.name,
        value: rateCard.sourceId,
    })), [rateCards]);
    return (_jsx(PageSection, { actionsLeft: _jsxs(FilterBar, { clear: hasChanged ? clearAll : undefined, children: [_jsx(SearchInput, { placeholder: "Search", defaultValue: state.search, onEnter: (value) => setState('search', value) }), _jsx(FilterDropdown, { options: rateCardOptions, placeholder: "Rate card", value: state.rateCardIds, multiselect: true, onUpdate: (value) => setState('rateCardIds', value) })] }), children: !!filteredList && (_jsx(DeliveryGroupsTable, { rateCardOptions: rateCardOptions !== null && rateCardOptions !== void 0 ? rateCardOptions : [], clients: filteredList, runRequest: runRequest })) }));
};
