import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Modal, ModalRow, ModalSection, Textarea } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateInvoiceAccount } from '../api';
export function AddAccountNotesModal({ account, onSubmit, onClose, }) {
    const { register, watch, handleSubmit } = useForm({
        defaultValues: {
            note: account.note,
        },
    });
    const { isLoading, isSuccess, runRequest, error } = useUpdateInvoiceAccount();
    const note = watch('note');
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    const submitHandler = ({ note }) => {
        runRequest({
            _id: account._id,
            name: account.name,
            isActive: account.isActive,
            periodicity: account.periodicity,
            splitInvoiceSpecByMonth: account.splitInvoiceSpecByMonth,
            note,
        });
    };
    return (_jsxs(Modal, { title: "Account notes", onClose: onClose, size: "medium", footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", disabled: (!account.note && !note) || isLoading || account.note === note, isLoading: isLoading, onClick: () => handleSubmit(submitHandler)(), text: 'Save' })] }), children: [_jsx(ModalSection, { children: _jsx(ModalRow, { children: _jsx(Textarea, Object.assign({}, register('note'), { placeholder: "Add your account notes...", maxLength: 250, height: 164 })) }) }), error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) }))] }));
}
