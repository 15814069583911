import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Select, Input, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateInvoiceClient } from '../api';
export function EditDeliveryGroupModal({ rateCardOptions, client, onSubmit, onClose, }) {
    var _a, _b;
    const { register, watch, setValue, handleSubmit } = useForm({
        defaultValues: {
            externalCustomerId: client.externalCustomerId,
            externalLegalEntityId: client.externalLegalEntityId,
            rateCardSourceId: (_a = client.rateCardSourceId) === null || _a === void 0 ? void 0 : _a.sourceId,
        },
    });
    const { isLoading, isSuccess, error, runRequest } = useUpdateInvoiceClient();
    const externalCustomerId = watch('externalCustomerId');
    const externalLegalEntityId = watch('externalLegalEntityId');
    const rateCardSourceId = watch('rateCardSourceId');
    useEffect(() => {
        register('rateCardSourceId');
    }, []);
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    const submitHandler = ({ rateCardSourceId, externalCustomerId, externalLegalEntityId, }) => {
        runRequest({
            _id: client._id,
            externalCustomerId: externalCustomerId,
            externalLegalEntityId: externalLegalEntityId,
            rateCardSourceId: rateCardSourceId,
        });
    };
    const hasBeenModified = externalCustomerId !== client.externalCustomerId ||
        externalLegalEntityId !== client.externalLegalEntityId ||
        rateCardSourceId !== ((_b = client.rateCardSourceId) === null || _b === void 0 ? void 0 : _b.sourceId);
    return (_jsxs(Modal, { title: "Edit delivery group", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", disabled: !hasBeenModified, isLoading: isLoading, onClick: handleSubmit(submitHandler), text: 'Save' })] }), children: [_jsxs(ModalSection, { children: [_jsx(ModalRow, { children: _jsx(Select, { label: "Rate card", placeholder: "Select a rate card", options: rateCardOptions, value: rateCardSourceId, disabled: rateCardOptions.length === 0, onSelect: (val) => val && setValue('rateCardSourceId', val) }) }), _jsxs(ModalRow, { children: [_jsx(Input, Object.assign({}, register('externalCustomerId'), { label: "Customer ID", placeholder: "Add customer ID" })), _jsx(Input, Object.assign({}, register('externalLegalEntityId'), { label: "Entity ID", placeholder: "Add entity ID" }))] })] }), error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) }))] }));
}
