var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
const isUpdateFunction = (update) => typeof update === 'function';
const initialState = {
    error: undefined,
    data: undefined,
    isLoading: false,
    isError: false,
    isSuccess: false,
};
export function usePromise(promiseFunction, options = {}, deps = []) {
    const cancelPromise = useRef(false);
    const lastRunArgsRef = useRef();
    const [state, setState] = useState(initialState);
    const run = useCallback((...args) => __awaiter(this, void 0, void 0, function* () {
        cancelPromise.current = false;
        lastRunArgsRef.current = args;
        setState(({ data }) => (Object.assign(Object.assign({}, initialState), { data, isLoading: true })));
        try {
            const data = yield promiseFunction(...args);
            if (cancelPromise.current)
                return new Error('Cancelled');
            setState(Object.assign(Object.assign({}, initialState), { data, isSuccess: true }));
            return data;
        }
        catch (error) {
            if (cancelPromise.current)
                return new Error('Cancelled');
            setState(Object.assign(Object.assign({}, initialState), { isError: true, error: error }));
            return error;
        }
    }), deps);
    const setData = useCallback((update) => {
        setState((state) => (Object.assign(Object.assign({}, state), { data: isUpdateFunction(update) ? update(state.data) : update })));
    }, [setState]);
    const clear = useCallback(() => {
        setState(initialState);
    }, [setState]);
    const retry = useCallback(() => {
        if (lastRunArgsRef.current) {
            run(...lastRunArgsRef.current);
        }
    }, [run]);
    useLayoutEffect(() => {
        if (options.triggerOnMount) {
            run(...[]);
        }
        return () => {
            cancelPromise.current = true;
        };
    }, [run]);
    return Object.assign(Object.assign({}, state), { run, setData, clear, retry });
}
