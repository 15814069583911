import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Button, Select, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useFetchDepotOptions } from '../../api';
export const EditDepots = ({ onClose, onUpdate, selectedDepots, exceptionDepotsIds, }) => {
    const [newDepots, setNewDepots] = useState(selectedDepots);
    const [hasNotEdited, setHasNotEdited] = useState(true);
    const depotOptions = useFetchDepotOptions();
    const depotOptionsFiltered = depotOptions.filter((option) => selectedDepots.find(({ _id }) => option.value === _id) ||
        !exceptionDepotsIds.includes(option.value));
    useMemo(() => {
        setHasNotEdited(selectedDepots.length === newDepots.length &&
            selectedDepots.every(({ _id }) => newDepots.find(({ _id: newId }) => newId === _id)));
    }, [newDepots]);
    return (_jsx(Modal, { onClose: onClose, title: 'Edit depots', supportingText: 'Edit the depots linked to this section.', footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: 'Update', disabled: hasNotEdited || newDepots.length === 0, onClick: () => onUpdate(newDepots.map(({ _id, name }) => ({
                        _id,
                        name,
                    }))) })] }), children: _jsx(ModalSection, { children: _jsx(ModalRow, { children: _jsx(Select, { withSearch: true, label: "Depots", options: depotOptionsFiltered, placeholder: "Add depots", multiselect: true, value: newDepots.map((dep) => dep._id), onSelect: (values) => {
                        const depotsObjects = values.map((id) => {
                            var _a, _b;
                            return ({
                                _id: id,
                                name: (_b = (_a = depotOptions.find((d) => d.value === id)) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : '',
                            });
                        });
                        setNewDepots(depotsObjects);
                    } }) }) }) }));
};
