import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Heading, Input, Link, Paragraph } from '@gordon/web-ui';
import { AuthenticationLayout } from '../components/AuthenticationLayout';
import { Section } from '../components/Section';
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks/useAuth';
export const Login = () => {
    var _a;
    const { register, handleSubmit } = useForm();
    const { isAuthenticated, login, loginFailed, isLoading } = useAuth();
    if (isAuthenticated) {
        const to = (_a = sessionStorage.getItem('lastKnownPath')) !== null && _a !== void 0 ? _a : '/';
        sessionStorage.removeItem('lastKnownPath');
        return _jsx(Navigate, { to: to === '/login' ? '/' : to, replace: true });
    }
    return (_jsx(AuthenticationLayout, { sidebarIllustration: true, children: _jsxs(Section, { spacing: "large", children: [_jsxs(Section, { spacing: "x-small", textAlign: "center", children: [_jsx(Heading, { size: "h1", children: "Billing log in" }), _jsxs(Paragraph, { size: "large", children: ["Welcome to the Gordon Billing platform.", _jsx("br", {}), " Please enter your details to sign in."] })] }), _jsx("form", { onSubmit: handleSubmit(login), children: _jsxs(Section, { children: [_jsx(Input, Object.assign({ label: "Email", placeholder: "Enter your email", type: "email" }, register('email', { required: true }))), _jsx(Input, Object.assign({ label: "Password", placeholder: "Enter your password", type: "password" }, register('password', { required: true }))), loginFailed && (_jsx("div", { children: _jsx(Alert, { variant: "error", heading: "Incorrect email or password" }) })), _jsxs(Section, { spacing: "small", textAlign: "center", children: [_jsx(Button, { isLoading: isLoading, type: "submit", variant: "primary", text: "Sign in" }), _jsx(Paragraph, { size: "small", children: "By signing in, you agree to our Terms of Service and Privacy Policy" })] }), _jsx(Section, { textAlign: "center", children: _jsx(Paragraph, { children: _jsx(Link, { variant: "primary", href: `${process.env.LASTMILE_URL}/request-password`, children: "Forgot password?" }) }) })] }) })] }) }));
};
