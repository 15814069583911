import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Modal, ModalSection } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useRecalculateInvoices } from '../api';
export function RecalculateInvoicesModal({ ids, onSubmit, onClose, }) {
    const { isLoading, runRequest, error, isSuccess } = useRecalculateInvoices();
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    return (_jsx(Modal, { title: "Recalculate", supportingText: "This will recalculate this specific invoice.", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: "Recalculate", isLoading: isLoading, onClick: () => runRequest({ ids }) })] }), children: error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) })) }));
}
