import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
const container = document.getElementById('root');
if (!container)
    throw new Error('no root element');
const root = createRoot(container);
root.render(_jsx(AuthProvider, { children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
