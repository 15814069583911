import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Page, PageSection } from '@gordon/web-ui';
import styles from './ChangeLogModal.module.scss';
import { useFetchChangeLogDetails } from '../../api';
import { Overview } from './Overview';
export const ChangeLogModal = ({ sourceId, onClose, versionId }) => {
    if (!sourceId) {
        return null;
    }
    const { data: changeLogDetails } = useFetchChangeLogDetails(sourceId, versionId || '');
    return (_jsx(Page, { children: _jsx("div", { className: styles['ChangeLogModal__page-container'], "data-testid": "changeLogDetailModal", children: _jsx("div", { className: styles['ChangeLogModal__page-content-container'], children: _jsx(PageSection, { size: "h1", headingText: changeLogDetails === null || changeLogDetails === void 0 ? void 0 : changeLogDetails.name, actionsRight: _jsx(Button, { variant: "primary", text: "Close", onClick: onClose }), children: _jsx(Overview, { rateCard: changeLogDetails, showEditButton: false }) }) }) }) }));
};
