import { invoiceStatus } from '../api';
export const statusOptions = [
    { text: 'Active', value: 'active' },
    { text: 'Inactive', value: 'inactive' },
];
export const monthlySplitOptions = [
    { text: 'Yes', value: 'true' },
    { text: 'No', value: 'false' },
];
export const entriesOptions = [
    { text: 'Generated entries', value: 'undefined' },
    { text: 'Manual entries', value: 'true' },
];
export const invoicePeriodOptions = [
    { text: 'Weekly', value: 'weekly' },
    { text: 'Monthly', value: 'monthly' },
];
export const priceTypeOptions = [
    { text: 'Stop', value: 'stop' },
    { text: 'Hour', value: 'hour' },
];
export const invoiceStatusOptions = Object.values(invoiceStatus).map((value) => ({
    text: value,
    value,
}));
