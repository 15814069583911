import dayjs from 'dayjs';
const tokenKey = '__billing-token__';
const expiresKey = '__billing-expires__';
export function isTokenExpired() {
    const token = localStorage.getItem(tokenKey);
    const expiresAt = localStorage.getItem(expiresKey);
    return !expiresAt || !token || dayjs().isAfter(dayjs(expiresAt).subtract(5, 'seconds'));
}
export const isSuccessfulLogin = (body) => (body === null || body === void 0 ? void 0 : body.status) === 'OK';
export const getToken = () => {
    return localStorage.getItem(tokenKey);
};
export const setToken = (token, expiresAt) => {
    localStorage.setItem(tokenKey, token);
    localStorage.setItem(expiresKey, expiresAt.toISOString());
};
export const removeToken = () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expiresKey);
};
export const login = ({ email, password }) => fetch(`${process.env.API_ENDPOINT}/auth/login`, {
    method: 'POST',
    headers: {
        'content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ email, password }),
    mode: 'cors',
})
    .then((response) => response.json())
    .then((responseBody) => {
    if (!isSuccessfulLogin(responseBody)) {
        throw new Error('Login failed');
    }
    setToken(responseBody.payload.token, new Date(responseBody.payload.expiresAt));
    const lastKnownPath = sessionStorage.getItem('lastKnownPath');
    if (lastKnownPath) {
        sessionStorage.removeItem('lastKnownPath');
        window.location.href = lastKnownPath;
    }
});
export const logout = () => {
    removeToken();
    fetch(`${process.env.API_ENDPOINT}/auth/logout`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
    });
};
export const refreshToken = () => fetch(`${process.env.API_ENDPOINT}/auth/refresh`, {
    method: 'POST',
    headers: {
        'content-type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
})
    .then((response) => response.json())
    .then((responseBody) => {
    if (!isSuccessfulLogin(responseBody)) {
        throw new Error('refresh failed');
    }
    setToken(responseBody.payload.token, new Date(responseBody.payload.expiresAt));
});
