import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './Pagination.scss';
import { Paragraph } from '../Typography/index';
import { useDeviceWidth } from '../utils/hooks';
import { classNames } from '../utils/helperFunctions';
import { IconArrowLeft, IconArrowRight, IconChevronLeftDouble, IconChevronRightDouble, } from '../Icon';
function PaginateIconButton({ icon, onChange, disabled }) {
    return (_jsx("button", { type: "button", disabled: disabled, onClick: () => onChange(), className: `u-ui-pagination__icon-button`, children: icon }));
}
function PaginatePageButton({ page, onChange, selected }) {
    return (_jsx("button", { type: "button", onClick: () => onChange(page), className: classNames('u-ui-pagination__page-button', selected && 'u-ui-pagination__page-button--selected'), children: page }));
}
const getVariant = (currentPage, pages, isMobile) => {
    if (isMobile)
        return 'mobile';
    if (pages <= 9)
        return 'neutral';
    if (currentPage > 5 && currentPage + 4 < pages)
        return 'both';
    if (currentPage > 7 && currentPage + 7 > pages)
        return 'trailing';
    if (currentPage <= 7 && currentPage + 4 < pages)
        return 'leading';
    return undefined;
};
export function Pagination({ pages, currentPage, onChange }) {
    const { isMobile } = useDeviceWidth();
    const variant = getVariant(currentPage, pages, isMobile);
    const range = (start, end) => {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    };
    const startPages = range(1, 7);
    const endPages = range(pages - 6, pages);
    const dynamicPages = range(currentPage - 2, currentPage + 2);
    const allPages = range(1, pages);
    return (_jsxs("div", { className: "u-ui-pagination", children: [isMobile && (_jsx(PaginateIconButton, { onChange: () => onChange(1), disabled: currentPage === 1, icon: _jsx(IconChevronLeftDouble, { color: currentPage === 1 ? 'disabled' : 'default' }) })), _jsx(PaginateIconButton, { onChange: () => onChange(currentPage - 1), disabled: currentPage === 1, icon: _jsx(IconArrowLeft, { color: currentPage === 1 ? 'disabled' : 'default' }) }), variant === 'mobile' && (_jsxs(Paragraph, { children: [_jsx("span", { className: "u-ui-pagination__strong-number", children: currentPage }), " of", ' ', _jsx("span", { className: "u-ui-pagination__strong-number", children: pages })] })), variant === 'leading' && (_jsxs(_Fragment, { children: [startPages.map((page) => (_jsx(PaginatePageButton, { onChange: onChange, page: page, selected: page === currentPage }, page))), _jsx("span", { children: "..." }), _jsx(PaginatePageButton, { onChange: onChange, page: pages })] })), variant === 'neutral' &&
                allPages.map((page) => (_jsx(PaginatePageButton, { onChange: onChange, page: page, selected: page === currentPage }, page))), variant === 'both' && (_jsxs(_Fragment, { children: [_jsx(PaginatePageButton, { onChange: onChange, page: 1 }), _jsx("span", { children: "..." }), dynamicPages.map((page) => (_jsx(PaginatePageButton, { onChange: onChange, page: page, selected: page === currentPage }, page))), _jsx("span", { children: "..." }), _jsx(PaginatePageButton, { onChange: onChange, page: pages })] })), variant === 'trailing' && (_jsxs(_Fragment, { children: [_jsx(PaginatePageButton, { onChange: onChange, page: 1 }), _jsx("span", { children: "..." }), endPages.map((page) => (_jsx(PaginatePageButton, { onChange: onChange, page: page, selected: page === currentPage }, page)))] })), _jsx(PaginateIconButton, { onChange: () => onChange(currentPage + 1), disabled: currentPage === pages, icon: _jsx(IconArrowRight, { color: currentPage === pages ? 'disabled' : 'default' }) }), isMobile && (_jsx(PaginateIconButton, { onChange: () => onChange(pages), disabled: currentPage === pages, icon: _jsx(IconChevronRightDouble, { color: currentPage === pages ? 'disabled' : 'default' }) }))] }));
}
