import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
function findIndexAndSum(getValue, length, targetSum, startPosition = 0) {
    let currentSum = 0;
    let index = startPosition;
    for (let i = startPosition; i < length; i++) {
        if (currentSum >= targetSum) {
            break;
        }
        currentSum += getValue(i);
        index = i + 1;
    }
    return [index, currentSum];
}
function findSumFromIndex(getValue, length, startPosition = 0) {
    let currentSum = 0;
    for (let i = startPosition; i < length; i++) {
        currentSum += getValue(i);
    }
    return currentSum;
}
export default function VirtualList({ children, scrollRef, calculateRowHeight, rowHeight = 48, ContainerElement = 'div', FillingElement = 'div', throttleWait = 10, }) {
    const numberOfChildren = Children.count(children);
    const [rowState, setRowState] = useState({
        startRow: 0,
        endRow: 0,
        topOffset: 0,
        bottomOffset: 0,
    });
    const setRowRange = useCallback((scroll) => {
        var _a, _b;
        if (scroll == undefined) {
            //If something is broken like the scroll element is not found, reset the range
            setRowState({ startRow: 0, endRow: numberOfChildren, topOffset: 0, bottomOffset: 0 });
            return;
        }
        const scrollHeight = (_b = (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : document.documentElement.clientHeight;
        if (calculateRowHeight) {
            const [startIndex, topOffset] = findIndexAndSum(calculateRowHeight, numberOfChildren, Math.max(0, scroll - scrollHeight));
            const [endIndex] = findIndexAndSum(calculateRowHeight, numberOfChildren, scrollHeight * 2, startIndex);
            const bottomOffset = findSumFromIndex(calculateRowHeight, numberOfChildren, endIndex);
            setRowState({
                startRow: startIndex,
                endRow: endIndex,
                topOffset,
                bottomOffset,
            });
        }
        else {
            const rowsToDisplay = Math.floor((scrollHeight * 2) / rowHeight);
            const scrollOffset = Math.floor(scroll - rowsToDisplay - scrollHeight);
            const newStartRow = Math.round(Math.max(0, Math.floor(scrollOffset / rowHeight)));
            const newEndRow = Math.round(Math.min(newStartRow + rowsToDisplay, numberOfChildren));
            setRowState({
                startRow: newStartRow,
                endRow: newEndRow,
                topOffset: !!newStartRow ? newStartRow * rowHeight : 0,
                bottomOffset: numberOfChildren !== newEndRow ? (numberOfChildren - newEndRow) * rowHeight : 0,
            });
        }
    }, [numberOfChildren, scrollRef, rowHeight]);
    useEffect(() => {
        var _a, _b;
        setRowRange((_b = (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) !== null && _b !== void 0 ? _b : document.documentElement.scrollTop);
    }, [scrollRef, setRowRange]);
    useEffect(() => {
        var _a;
        const onScroll = throttle((event) => {
            const scrollTop = event.target instanceof HTMLElement
                ? event.target.scrollTop
                : document.documentElement.scrollTop;
            if (numberOfChildren !== 0) {
                setRowRange(scrollTop);
            }
        }, throttleWait);
        const scrollElement = (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) !== null && _a !== void 0 ? _a : document;
        scrollElement.addEventListener('scroll', onScroll);
        return () => {
            scrollElement.removeEventListener('scroll', onScroll);
        };
    }, [setRowRange, numberOfChildren, scrollRef]);
    return (_jsxs(ContainerElement, { className: "u-ui-virtual-list", children: [!!rowState.topOffset && _jsx(FillingElement, { style: { height: rowState.topOffset } }), Children.toArray(children).slice(rowState.startRow, rowState.endRow), !!rowState.bottomOffset && _jsx(FillingElement, { style: { height: rowState.bottomOffset } })] }));
}
