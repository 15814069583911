import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
const isNumber = (str) => /^-?\d+$/.test(str);
const formatValue = (value) => (isNumber(value) ? Number(value) : value);
export const useQueryParamState = (initialState) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const state = useMemo(() => {
        const params = searchParams;
        const state = Object.assign({}, initialState);
        for (const [key, value] of params.entries()) {
            if (key in state) {
                state[key] = (Array.isArray(initialState[key]) ? value.split(',').map(formatValue) : formatValue(value));
            }
        }
        return state;
    }, [searchParams, initialState]);
    const setState = useCallback((key, value) => {
        const formattedValue = Array.isArray(value) ? value.join(',') : String(value);
        const params = new URLSearchParams(location.search);
        if (params.get(key.toString()) === formattedValue) {
            return;
        }
        if (!formattedValue) {
            params.delete(key.toString());
        }
        else {
            params.set(key.toString(), formattedValue);
        }
        setSearchParams(params);
    }, [location.search]);
    const clearAll = useCallback(() => {
        const params = new URLSearchParams(location.search);
        for (const key in initialState) {
            params.delete(key);
        }
        setSearchParams(params);
    }, [searchParams, initialState]);
    const hasChanged = useMemo(() => {
        for (const key of Object.keys(initialState)) {
            if (state[key] !== initialState[key]) {
                return true;
            }
        }
        return false;
    }, [initialState, state]);
    return [state, setState, hasChanged, clearAll];
};
