import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Select, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useFetchDepotOptions } from '../../api';
export const DuplicateSectionModal = ({ onClose, onAdd, depotsIds, }) => {
    const [depots, setDepots] = useState([]);
    const depotOptions = useFetchDepotOptions();
    const depotOptionsFiltered = depotOptions.filter((option) => !depotsIds.includes(option.value));
    return (_jsx(Modal, { size: "medium", onClose: onClose, title: "Duplicate section", supportingText: "By duplicating a section you are creating an new exception. The table information from the section being duplicated, will be added to the depot/s selected below to create a new exception.", footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: 'Add', onClick: () => onAdd(depots.map((id) => {
                        var _a, _b;
                        return ({
                            _id: id,
                            name: (_b = (_a = depotOptions.find((d) => d.value === id)) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : '',
                        });
                    })), disabled: depots.length === 0 })] }), children: _jsx(ModalSection, { children: _jsx(ModalRow, { children: _jsx(Select, { withSearch: true, options: depotOptionsFiltered, placeholder: "Select depots", multiselect: true, value: depots, onSelect: (values) => setDepots(values) }) }) }) }));
};
