import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Row, Table, TableBody, TableHead, Column, HeaderColumn, MeatballMenu, MenuListItem, Button, IconEdit1, } from '@gordon/web-ui';
import { useSort } from '../../hooks/useSort';
import { EditDeliveryGroupModal } from '../../modals/EditDeliveryGroupModal';
export const DeliveryGroupsTable = ({ clients, rateCardOptions, runRequest, }) => {
    const [showEditDeliveryGroupModal, setShowEditDeliveryGroupModal] = useState();
    const { sortedList: sortedClients, sortField, sortOrder, changeSorting } = useSort(clients);
    return (_jsxs("div", { children: [_jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { title: clients.length > 0 ? `${clients.length} Delivery Groups` : 'Loading', children: [_jsx(HeaderColumn, { width: '25cqw', isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'name', onClick: () => changeSorting('name'), children: "Delivery group" }), _jsx(HeaderColumn, { width: '25cqw', children: "Rate card" }), _jsx(HeaderColumn, { width: '20cqw', children: "Customer ID" }), _jsx(HeaderColumn, { width: '20cqw', children: "Entity ID" }), _jsx(HeaderColumn, { width: 48 })] }), _jsx(TableBody, { children: (sortedClients === null || sortedClients === void 0 ? void 0 : sortedClients.length) === 0 ? (_jsx(Row, { children: _jsx(Column, { span: 100, text: "No delivery groups found" }) })) : (sortedClients === null || sortedClients === void 0 ? void 0 : sortedClients.map((client) => (_jsxs(Row, { children: [_jsx(Column, { text: client.name, href: `/clients/${client._id}` }), client.rateCardSourceId ? (_jsx(Column, { text: client.rateCardSourceId.name, href: `/rate-cards/${client.rateCardSourceId.sourceId}` })) : (_jsx(Column, { children: _jsx(Button, { variant: "secondary", text: "Link rate card", size: "small", onClick: () => setShowEditDeliveryGroupModal(client) }) })), _jsx(Column, { text: client.externalCustomerId }), _jsx(Column, { text: client.externalLegalEntityId }), _jsx(Column, { children: _jsx("span", { style: { display: 'flex', justifyContent: 'flex-end' }, children: _jsx(MeatballMenu, { variant: "tertiary", children: _jsx(MenuListItem, { leadingElement: _jsx(IconEdit1, {}), value: "Edit delivery group details", text: "Edit delivery group details", onClick: () => setShowEditDeliveryGroupModal(client) }) }) }) })] }, client._id)))) })] }), showEditDeliveryGroupModal && rateCardOptions && (_jsx(EditDeliveryGroupModal, { rateCardOptions: rateCardOptions, client: showEditDeliveryGroupModal, onSubmit: () => {
                    runRequest();
                    setShowEditDeliveryGroupModal(null);
                }, onClose: () => setShowEditDeliveryGroupModal(null) }))] }));
};
