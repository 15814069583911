import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import { Button, Input, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
export const AddEditThresholdModal = ({ onClose, onUpdate, threshold, thresholds, }) => {
    const [newThresholdValue, setThresholdValue] = useState(threshold);
    const [thresholdExists, setThresholdExists] = useState(false);
    const firstRender = useRef(true);
    useMemo(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (newThresholdValue && isNaN(newThresholdValue)) {
            setThresholdExists(false);
        }
        else if (newThresholdValue === 0) {
            setThresholdExists(true);
        }
        else if (thresholds.find((t) => t.threshold === newThresholdValue)) {
            setThresholdExists(true);
        }
        else
            setThresholdExists(false);
    }, [newThresholdValue]);
    return (_jsx(Modal, { onClose: onClose, title: threshold ? 'Edit threshold value' : 'Add threshold value', supportingText: threshold
            ? 'Update the threshold to another value that is not currently being used in this section.'
            : 'Add an additional threshold column to the section by providing a value below.', footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", text: "Cancel", onClick: onClose }), _jsx(Button, { variant: "primary", text: threshold ? 'Update' : 'Add', disabled: thresholdExists ||
                        !newThresholdValue ||
                        newThresholdValue === threshold ||
                        Number.isNaN(newThresholdValue) ||
                        newThresholdValue < 1, onClick: () => newThresholdValue && onUpdate(newThresholdValue) })] }), children: _jsx(ModalSection, { children: _jsx(ModalRow, { children: _jsx(Input, { label: "Threshold", defaultValue: newThresholdValue, placeholder: "Add threshold", type: "number", onChange: (e) => setThresholdValue(parseInt(e.target.value)), errorMessage: thresholdExists ? 'Same threshold value already exists in this section' : undefined }) }) }) }));
};
