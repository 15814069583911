import { toEntries } from './index';
export const constructApi = (config) => {
    return (client, handleResponse) => {
        const api = {};
        toEntries(config).forEach(([apiGroupName, apifuncs]) => {
            api[apiGroupName] = toEntries(apifuncs).reduce((acc, [apiName, callback]) => (Object.assign(Object.assign({}, acc), { [apiName]: handleResponse
                    ? (...params) => callback(client)(...params).then(handleResponse)
                    : callback(client) })), {});
        });
        return api;
    };
};
