import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Input, Modal, ModalRow, ModalSection } from '@gordon/web-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateArticle } from '../api';
export function EditXledgerNumberModal({ id, externalId, onSubmit, onClose, }) {
    const { register, watch, handleSubmit } = useForm({
        defaultValues: {
            xledgerNumber: externalId,
        },
    });
    const { isLoading, isSuccess, error, runRequest } = useUpdateArticle(id);
    const xledgerNumber = watch('xledgerNumber');
    useEffect(() => {
        if (isSuccess) {
            onSubmit();
        }
    }, [isSuccess]);
    const submitHandler = ({ xledgerNumber }) => {
        runRequest({
            externalId: xledgerNumber,
        });
    };
    return (_jsxs(Modal, { title: "Edit article number", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: onClose, text: "Cancel" }), _jsx(Button, { variant: "primary", disabled: !xledgerNumber || externalId === xledgerNumber || isLoading, isLoading: isLoading, onClick: handleSubmit(submitHandler), text: 'Save' })] }), children: [_jsx(ModalSection, { children: _jsx(ModalRow, { children: _jsx(Input, Object.assign({}, register('xledgerNumber'), { type: "number", label: "Xledger article number", placeholder: "Add number" })) }) }), error && (_jsx(ModalSection, { children: _jsx(Alert, { variant: "error", heading: "Something went wrong" }) }))] }));
}
