import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from '@gordon/web-ui';
const statusToVariant = (status) => {
    switch (status) {
        case 'Ready to invoice':
        case 'Ready to export':
        case 'New':
            return 'brand';
        case 'Not calculated':
        case 'Unable to calculate':
        case 'Unable to export':
            return 'error';
        case 'Invoice exported':
        case 'Sent':
        case 'Manually handled':
        case 'No charge':
        default:
            return 'success';
    }
};
export const StatusBar = ({ status }) => {
    return _jsx(Badge, { variant: statusToVariant(status), text: status });
};
