import dayjs from 'dayjs';
export function forceDownload(url, filename = '') {
    if (!url)
        return;
    const a = document.createElement('a');
    a.download = filename;
    a.href = url;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}
export function classNames(...args) {
    return args.filter((val) => val).join(' ');
}
export function amount(number, currency) {
    if (number == undefined || !currency) {
        return '-';
    }
    return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(number);
}
export function formatDate(date) {
    return dayjs(date).format('YYYY/MM/DD');
}
export function formatDateAndTime(date) {
    return dayjs(date).format('HH:mm DD/MM/YYYY');
}
export function formatTime(date) {
    return dayjs(date).format('HH:mm');
}
export function validateDateFormat(date) {
    const re = /((?:[12]\d{3}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01]))|\d{8})/;
    return re.test(date);
}
export function addOrRemove(array, value) {
    return array.includes(value) ? array.filter((item) => item !== value) : [...array, value];
}
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function lowerCaseFirstLetter(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}
export function prettifyCamelCaseText(text) {
    // convert a camelCase string to space-separated strings with first letter capitalized
    if (!text)
        return '';
    const words = text.match(/[A-Za-z0-9][a-z]*/g) || [];
    return words
        .map((w, i) => (i === 0 ? capitalizeFirstLetter(w) : lowerCaseFirstLetter(w)))
        .join(' ');
}
export function getTemporaryId() {
    return `temp_${new Date().getTime()}`;
}
export function deepEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}
